import React from 'react';
import {OptionsType, StylesConfig, ValueType} from "react-select";
import ValidateFieldRequired from "../ValidateFieldRequired";
import CreatableSelect from 'react-select/creatable';
import useTranslate from "../../../hooks/useTranslate";
import {SelectComponentsConfig} from "react-select/src/components";

interface IProps {
    [key: string]: any;

    id?: string;
    name: string;
    value?: ValueType<any, any>;
    options: OptionsType<any>;
    invalid?: boolean;
    readonly?: boolean;
    disabled?: boolean;
    defaultValue?: ValueType<any, any>;
    isMulti?: boolean
    required?: boolean;
    closeMenuOnSelect?: boolean;
    placeholder?: string;
    styles?: StylesConfig<any, any>
    className?: string;
    loading?: boolean;
    components?: SelectComponentsConfig<any, any>
    onChange?: (value: Array<ValueType<any, any>> | ValueType<any, any>) => void;
    menuPosition?: 'absolute' | 'fixed'
    onInputChange?: (value: string) => void;
    inputValue?: string
}

const MultiSelectComponent = (props: IProps) => {

    const {
        id,
        name,
        value,
        onChange,
        className,
        options,
        placeholder,
        required,
        disabled,
        loading,
        onInputChange,
        menuPosition = "fixed",
        defaultValue,
        sortOptions = true,
        closeMenuOnSelect = true, styles,
        isMulti, components, inputValue
    } = props;

    let opts: OptionsType<any> = [];
    if (options && options.length > 0) {
        if (sortOptions) {
            opts = [...(options as { label: string, value: string }[])].filter(item => item.label).sort((a, b) => a.label.toString().localeCompare(b.label.toString()))
        } else {
            opts = [...options]

        }
    }

    const {t} = useTranslate("common");

    return <ValidateFieldRequired
        disabled={disabled}
        value={value}
        required={required}>
        <>
            <CreatableSelect isMulti={isMulti ?? false}
                             isLoading={loading}
                             classNamePrefix="react-select"
                             placeholder={placeholder ? placeholder : `${t('select_placeholder')}`}
                             className={`${className ?? ""} `}
                             id={id}

                             components={components}
                             defaultValue={defaultValue}
                             menuPosition={menuPosition ?? undefined}
                             isDisabled={disabled}
                             name={name}
                             styles={styles}

                             inputValue={inputValue}
                             closeMenuOnSelect={closeMenuOnSelect}
                             value={value}
                             onChange={(value: any) => onChange ? onChange(value) : null}
                             onInputChange={(e) => {
                                 if (onInputChange) onInputChange(e);
                             }}
                             options={opts}
            />
        </>
    </ValidateFieldRequired>
};

export default MultiSelectComponent;

import {actionsModal, ModalTypes} from "../../../../store/Modal/Slice";
import ModalComponent, {ModalProps} from "../ModalComponent/ModalComponent";
import {useDispatch, useSelector} from "react-redux";
import React, {useState} from "react";

interface IProps {
  open: boolean;
  data: IPropsDynamicComponentModal;
}


export interface IPropsDynamicComponentModal extends Omit<ModalProps, "type" | "isOpen" | "toggle" | "isLoading" | "onSuccess"> {
  type: ModalTypes.DYNAMIC_COMPONENT_MODAL;
  component?: React.ReactNode;
  value?: any;
  identifierKey?: string;
  renderComponent?: (value: any, onChangeValue: (v: any) => void) => React.ReactElement<HTMLElement>;
  typeForm?: "button" | "submit" | "reset";
  state?: string;
  toggle?: () => void;
  onSuccess?: (data?: any) => void;
  closeAfterSuccess?: boolean;

}

const DynamicComponentModal = ({
                                 data: {
                                   component,
                                   size,
                                   footer,
                                   title,
                                   typeForm,
                                   noFooter,
                                   onSuccess,
                                   labelCancel,
                                   labelSuccess,
                                   form,
                                   zIndex,
                                   value,
                                   header,
                                   disabled,
                                   state,
                                   renderComponent,
                                   toggle: onToggle,
                                   closeAfterSuccess = false,
                                   identifierKey,
                                 }, open
                               }: IProps) => {

  const dispatch = useDispatch();
  const stateReducers: any = useSelector(state => state);

  const [newValue, setValue] = useState(value)

  function toggle() {
    dispatch(actionsModal.closeModal({type: ModalTypes.DYNAMIC_COMPONENT_MODAL, identifierKey}));
  }

  const loading = Object.keys(stateReducers).some(key => {
    return stateReducers[key]?.state === state || stateReducers[key]?.mfaStates === state || stateReducers[key]?.linkStates === state;
  });

  return (
    <ModalComponent
      size={size}
      type={typeForm}
      title={title}
      footer={footer}
      isOpen={open}
      noFooter={noFooter}
      onSuccess={() => {
        if (onSuccess) onSuccess(newValue);
        if (closeAfterSuccess) toggle();
      }}
      labelCancel={labelCancel}
      labelSuccess={labelSuccess}
      disabled={disabled}
      form={form}
      zIndex={zIndex}
      isLoading={loading}
      header={header}
      toggle={onToggle ?? toggle}>
      <>
        {component}
        {renderComponent && renderComponent(newValue, setValue)}
      </>
    </ModalComponent>
  )
}

export default DynamicComponentModal;
import {getParseToken} from '../UtilsService';
import fetchJson from '../../lib/fetchJson';
import {Condition, ModelsResponse, SbxQuery, SbxQueryResponse, SbxResponse} from '../../types/Sbx';
import {get, post} from '../../network';
import {AnyData} from '../../types/AnyData';
import {organizationFindQuery} from "../../utils";


export const getSbxModelFields = async ({
                                          provider,
                                          findUrl,
                                        }: { provider: { query: string, page?: number, size?: number, name?: string }, findUrl?: string }) => {


  try {

    const sbx_query = (JSON.parse(provider.query) as SbxQueryResponse);

    let query: SbxQuery = {
      where: sbx_query?.WHERE ?? sbx_query?.where ?? [],
      row_model: sbx_query?.ROW_MODEL ?? sbx_query?.row_model ?? provider.name
    };


    if (!findUrl) {
      query = {...query, size: 1000, page: 1}
    }

    if (provider.page) {
      query.page = provider.page
    }

    if (provider.size) {
      query.size = provider.size
    }

    if (sbx_query?.FETCH?.length > 0 || sbx_query.fetch?.length > 0) {
      query.fetch = sbx_query.FETCH ?? sbx_query.fetch ?? [];
    }


    query.where = await organizationFindQuery({where: query.where ?? [], row_model: sbx_query.row_model})

    // const findUrl = query.size ? "find" : "find_all"

    const response: SbxResponse = await post(`/api/data/v1/row/model/${findUrl ?? "find"}`, query).then(res => res);

    if (response?.success) {
      if (query?.fetch && response?.fetched_results && Object.keys(response.fetched_results)?.length > 0) {
        for (let fetched of query.fetch) {
          let entities = fetched.split('.');
          for (const item of (response?.results || [])) {
            const nItem: any = item;
            for (const fetched_result in response.fetched_results) {
              // Fetched, get the base reference if exist another one.
              fetched = entities.length > 1 ? entities[0] : fetched;
              if (nItem[fetched] in response.fetched_results[fetched_result]) {
                nItem[fetched] = response.fetched_results[fetched_result][nItem[fetched]];
              }
            }
            // Use entities since 2 position, because the first is the base and the rest is the references.
            // Ex: ["customer.company"]
            if (entities.slice(1,).length > 0) {
              for (const entity of entities.slice(1,)) {
                for (const fetched_result in response.fetched_results) {
                  if (nItem[fetched] && nItem[fetched].hasOwnProperty(entity) && response.fetched_results.hasOwnProperty(fetched_result) &&
                    nItem[fetched][entity] in response.fetched_results[fetched_result]) {
                    nItem[fetched][entity] = response.fetched_results[fetched_result][nItem[fetched][entity]];
                  }
                }
              }
            }
          }
        }
      }
      return response;
    } else {
      return {success: false, error: response.error};
    }


  } catch (error) {
    console.error(error);
    return {success: false, error} as SbxResponse;
  }
}

export const insertSbxModelService = async (data: { row_model: string, rows: any[] }) => {
  return post("/api/data/v1/row/model/insert", data).then(res => ({...res, row_model: data.row_model}))
}

export const deleteSbxModelService = async (data: { row_model: string, keys: string[] }) => {

  const params = {
    row_model: data.row_model,
    where: {
      keys: data.keys
    }
  }

  return post("/api/data/v1/row/model/delete", params)
}

export const updateSbxModel = async ({token, data}: { token: string, data: SbxQuery }) => {
  token = getParseToken(token);
  try {
    return await fetchJson('/api/v2/sbx/update', {
      method: 'POST',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
        'authorization': `${token}`
      },
      redirect: 'follow',
      referrerPolicy: 'no-referrer',
      body: JSON.stringify(data)
    });

  } catch (error) {
    console.error(error);
    return {success: false, error};
  }
};


export const findByAll = async (params: { row_model: string, fetch?: string[], where?: Condition[] }) => {
  if (!params.where) {
    params.where = [];
  }

  if (params.where) {
    params.where = await organizationFindQuery({where: params.where, row_model: params.row_model})
  }

  return post(`/api/data/v1/row/model/find_all`, params).then(res => {
    if (res?.success) {
      res.items = res.results;
    }
    return res;
  });
};

export function cloudScriptRun(params: { key?: string, params: any }) {
  return post(`/api/cloudscript/v1.5/run`, params);
}


export function findAllModels(params?: AnyData) {
  return get(`/api/data/v1/row/model/list`, params);
}

export function updateModelRow(rows: AnyData[], row_model: string) {
  return post("/api/data/v1/row/model/update", {row_model, rows});
}

export function insertModelRow(rows: AnyData[], row_model: string) {
  return post("/api/data/v1/row/model/insert", {row_model, rows});
}

export async function findByModel(params: {
  where?: Condition[] | { keys: string[] };
  fetch?: string[];
  row_model: string;
  page?: number;
  size?: number;
  noGetOrganization?: boolean
}) {

  if (params.where && !params.noGetOrganization) {
    params.where = await organizationFindQuery({where: params.where, row_model: params.row_model})
  }

  return post(`/api/data/v1/row/model/find`, params)
    .then((res: any) => {
      if (res?.success) {
        res.items = res.results;
        res.total_items = res.row_count;
        res.row_model = params.row_model;
        return res;
      } else {
        return res;
      }
    });
}

export async function findAllByModel(params: {
  where?: Condition[] | { keys: string[] };
  fetch?: string[];
  row_model: string;
  page?: number;
  size?: number;
}) {

  if (params.where) {
    params.where = await organizationFindQuery({where: params.where, row_model: params.row_model})
  }

  return post(`/api/data/v1/row/model/find_all`, params)
    .then((res: any) => {
      if (res?.success) {
        res.items = res.results;
        res.total_items = res.row_count;
        res.row_model = params.row_model;
        return res;
      } else {
        return res;
      }
    });
}

export async function fetchModelByReferences(ids: number[]): Promise<{ [key: string]: ModelsResponse }> {
  let keysId: any = {};
  for await (let model of ids) {
    const res = await findAllModels({model})
    if (res.success && res.items) {
      keysId[model] = res.items[0];
    }
  }
  return keysId
}


export function updateDictionary(params: {
  model: string;
  fetch: string[];
  fields: string[];
}, key?: string) {
  return cloudScriptRun({
    params: {
      type: "dictionary",
      ...params
    },
    key
  })
}

import {
    Button,
    Card,
    CardBody,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    FormGroup,
    Input,
    Table,
    UncontrolledButtonDropdown
} from 'reactstrap';
import React, {CSSProperties, useEffect, useState} from 'react';
import SpinnerComponent from './SpinnerComponent';
import {Pagination, Switch} from 'antd';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faEllipsisV, faEnvelope, faFileExport} from '@fortawesome/free-solid-svg-icons';
import Permission from '../AuthorityPermission/Permission';
import {Permissions} from '../../types/Permissions';
import {
    capitalize,
    checkIsValidHexColor,
    checkValidColor,
    containsNumbers,
    convertDateToYYYYMM,
    convertDateToYYYYMMDD,
    convertDateToYYYYMMDDHHmmaa,
    convertDateToYYYYMMMDD,
    convertNumberDateToDate,
    convertTableRowsToCSVString,
    DEFAULT_SIZE,
    downloadTextToFile,
    filterData,
    getCompoundName,
    getContentFileName,
    getObjValueInDeep,
    isEncoded,
    IsJsonString,
    openInNewTab,
    removeDuplicateFromArray,
    uuidV4
} from '../../utils';
import {ModelsResponse, SbxModelField} from '../../types/Sbx';
import PopoverComponent from './PopoverComponent';
import useTranslate from '../../hooks/useTranslate';
// @ts-ignore
import locale from 'react-json-editor-ajrm/locale/en';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../store/Reducers';
import {actionHistory} from '../../store/SearchReducer';
import {ListProviderOption} from '../../types/Task';
import DropdownMenuHeader from './DropdownComponent/DropdownMenuHeader';
import {SbxCrmDataColumn, TypeFIle} from '../../types/User';
import {ButtonType} from '../LayoutComponenents/types/ButtonType';
import {ColumnsLabels, FormatRules} from '../../types/Field';
import EditorComponent from "./EditorComponent/EditorComponent";
import {Content} from "../../types/Folder/Content";
import {downloadFileService, getFile} from "../../services/UtilsService";
import {FileTypeIcon} from "./ContentEngine";
import useIgnoreFirstEffect from "../../hooks/useIgnoreFirstEffect";
import RenderImageViewer from "./RenderImageViewer/RenderImageViewer";
import {useRouter} from "next/router";
import TableColumnFilterMenuComponent from "./TableColumnFilterMenuComponent/TableColumnFilterMenuComponent";
import TableCustomColumnMenuComponent from "./TableColumnFilterMenuComponent/TableCustomColumnMenuComponent";
import useAsyncEffect from "../../hooks/useAsyncEffect";
import {findAllModels} from "../../services/backend/SbxService";
import wsIcon from "../../public/assets/images/wsicon.jpg";
import Image from "next/image";

export type CustomTableColumnType =
    'String'
    | 'Date'
    | 'Formatter'
    | SbxModelField
    | 'Reference'
    | 'Fixed'
    | 'Icon'
    | 'Json'
    | 'NumberDate'
    | 'Boolean'
    | 'List_provider'
    | 'Year_month'
    | 'Custom'
    | 'DateTime'
    | 'ReactNode'
    | 'Color'
    | "Array"
    | 'ArrayObject'
    | 'Document'
    | 'Image'
    | 'Mailto'
    | 'Whatsapp'

export interface Column extends FormatRules {
    name: string;
    data?: any;
    header: string
    parent?: string;
    reference?: string;
    className?: string;
    style?: CSSProperties;
    action?: React.ReactNode;
    isSort?: boolean
    isTotalColumn?: boolean;
    headerClassName?: string
    customShowColumn?: (value: any, index?: number) => void;
    type?: CustomTableColumnType
    zIndex?: number;
    value?: string;
    column_reference?: SbxCrmDataColumn

}

export interface Action {
    label?: React.ReactNode;
    disabled?: boolean;
    title?: string;
    onAction?: (row: any, index?: number) => void;
    permission?: Permissions | Permissions[];
    visible?: boolean;
    visibleRow?: (row: any) => boolean;
    custom?: boolean;
    customComponent?: (row: any) => JSX.Element | JSX.Element[];
    type?: 'primary' | 'secondary' | 'danger' | 'success' | 'warning' | string;
}

interface IProps {
    id?: string
    columns: Column[];
    hideColumnsReports?: Column[];
    data: any[];
    actions?: Action[];
    loading?: boolean;
    pagination?: boolean;
    showSizeChanger?: boolean
    currentPage?: number
    rowAction?: (row: any, header: Column) => void;
    tableShading?: boolean;
    onChangePage?: (page: number, size: number) => void;
    totalData?: number
    actionsType?: 'list' | 'button',
    getColumns?: (columns: Column[]) => void;
    columnsSetting?: boolean;
    onShowSizeChange?: (page: number, size: number) => void;
    filter?: boolean;
    useLocalPage?: boolean;
    exportButtons?: { type: TypeFIle, color: ButtonType }[]
    actionsColumnLabel?: string
    actionsColumnClass?: string
    removeEmptyColumns?: boolean
    sortableTable?: boolean;
    shadow?: "none" | "lg" | "sm";
    localFilter?: boolean;
    isReportTable?: boolean
    loadSbxModels?: string[];
    updateColumnsProps?: ({reportProp, column_name, value, subProp}: {
        column_name: string,
        value: any,
        subProp?: string,
        reportProp: 'sort' | 'custom_column' | 'columns_to_summarize'
    }) => void;
}

const animateColumnMove = (columnIndex: number, newPosition: number) => {
    const ths = document.querySelectorAll("th");
    const distance = newPosition - columnIndex;

    // Movemos la columna actualmente en la posición "columnIndex" hacia su nueva posición
    ths[columnIndex].style.transform = `translateX(${distance * 100}%)`;

    // Establecemos la opacidad de la columna en su nueva posición a 0
    ths[newPosition].style.opacity = "0";

    // Agregamos una transición suave a la columna que se está moviendo
    ths[columnIndex].style.transition = `transform 0.3s ease-in-out, opacity 0.3s ease-in-out`;

    // Cuando la transición termine, eliminamos la transformación y la transición y restablecemos la opacidad de la columna en su nueva posición a 1
    ths[columnIndex].addEventListener("transitionend", () => {
        ths[columnIndex].style.transform = "";
        ths[columnIndex].style.transition = "";
        ths[newPosition].style.opacity = "1";
    }, {once: true});
};

export const CustomTableComponent = (
    {
        columns: columnsData,
        data,
        filter: search = true,
        actions,
        loading,
        pagination = true,
        tableShading,
        rowAction,
        onChangePage,
        totalData,
        showSizeChanger,
        actionsType,
        id,
        getColumns,
        columnsSetting,
        onShowSizeChange,
        useLocalPage = false,
        exportButtons = [],
        actionsColumnLabel = '',
        actionsColumnClass,
        removeEmptyColumns,
        currentPage,
        shadow, loadSbxModels, isReportTable,
        localFilter, updateColumnsProps, hideColumnsReports
    }: IProps) => {

    const {page, text} = useSelector((state: RootState) => state.SearchReducer);
    const [newRows, setNewRows] = useState<Array<any>>([]);
    const [excludeColumns, setExcludeColumns] = useState<string[]>([]);
    const [columns, setColumns] = useState<Column[]>([]);
    const [copyColumns, setCopyColumns] = useState<Column[]>([]);
    const [filterLocal, setFilterLocal] = useState('');
    const [filter, setFilter] = useState('');
    const [selectAll, setSelection] = useState(true);
    const {t} = useTranslate('common');
    const [size, setSize] = useState(DEFAULT_SIZE);
    const dispatch = useDispatch();
    const [localPage, setLocalPage] = useState(1);
    const [rows, setRows] = useState<any[]>([]);
    const [sbxModels, setSbxModels] = useState<ModelsResponse[]>([])
    const router = useRouter();

    useAsyncEffect(async () => {
        if (loadSbxModels && loadSbxModels.length > 0) {
            const sbxModels = await findAllModels();
            if (sbxModels?.success && sbxModels.items) {
                setSbxModels(sbxModels.items.filter(model => loadSbxModels.includes(model.name)))
            }
        }

    }, [loadSbxModels])

    const [draggedColumnIndex, setDraggedColumnIndex] = useState<number | null>(null);

    const [overColumnIndex, setOverColumnIndex] = useState<number | null>(null);

    const handleDragStart = (index: number) => {
        setDraggedColumnIndex(index);
    };

    const handleDragEnter = (index: number) => {
        setOverColumnIndex(index);
    };

    const handleDragEnd = () => {
        if (draggedColumnIndex !== null && overColumnIndex !== null) {
            const newColumns = [...columns];
            const draggedColumn = newColumns[draggedColumnIndex];
            newColumns.splice(draggedColumnIndex, 1);
            newColumns.splice(overColumnIndex, 0, draggedColumn);
            setColumns(newColumns);
            setDraggedColumnIndex(null);
            setOverColumnIndex(null);

            if (updateColumnsProps) {
                reportUpdateColumns({columns: newColumns.map(column => column.name)})
            }

            // Animamos el movimiento de cada columna que se está moviendo
            if (draggedColumnIndex < overColumnIndex) {
                for (let i = draggedColumnIndex; i < overColumnIndex; i++) {
                    animateColumnMove(i, i + 1);
                }
            } else {
                for (let i = overColumnIndex; i < draggedColumnIndex; i++) {
                    animateColumnMove(i, i + 1);
                }
            }
        }
    };

    useEffect(() => {
        if (search) {
            setNewRows(filterData(data, text));
        } else {
            setNewRows(data);
        }
    }, [data, text]);

    useEffect(() => {
        if (localFilter) {
            if (filterLocal.trim()) {
                setNewRows(filterData(data, filterLocal));
            } else {
                setNewRows(data);
            }
        }
    }, [data, filterLocal]);

    React.useEffect(() => {
        if (useLocalPage && !onChangePage) {
            setLocalPage(1);
        }
    }, [data]);

    React.useEffect(() => {
        if (currentPage && currentPage > 0 && useLocalPage && onChangePage) {
            setLocalPage(currentPage)
        }
    }, [currentPage]);

    React.useEffect(() => {
        if (!useLocalPage) {
            setLocalPage(page);
        }
    }, [page]);

    useIgnoreFirstEffect(() => {
        if (onChangePage) {
            onChangePage(localPage, size);
        }
    }, [localPage])

    React.useEffect(() => {
        setRows((pagination && !totalData ? (size > 0 ? newRows.slice((localPage - 1) * size, (localPage - 1) * size + size) : newRows) : newRows));
    }, [pagination, totalData, localPage, size, newRows]);

    const getValue = ({value, header, index}: {
        value: string | number | boolean | ListProviderOption | { [key: string]: any } | Content,
        header: Column,
        index?: number
    }) => {

        if (header.type) {
            const cases: { [column: string]: () => any } = {
                [SbxModelField.DATE]: () => value ? convertDateToYYYYMMMDD(value as string) : "",
                Date: () => value ? convertDateToYYYYMMMDD(value as string) : "",
                DateTime: () => convertDateToYYYYMMDDHHmmaa(new Date(value as string)),
                NumberDate: () => convertDateToYYYYMMDD(convertNumberDateToDate(value as number)),
                Reference: () => typeof value === 'string' ? value : getObjValueInDeep(value as {
                    [key: string]: any
                }, header.value as string),
                Formatter: () => {
                    const formatter = new Intl.NumberFormat('en-US', {
                        style: 'currency',
                        currency: 'USD'
                        // These options are needed to round to whole numbers if that's what you want.
                        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
                    });


                    return formatter.format(value as number);
                },
                Formatter0: () => {
                    const formatter = new Intl.NumberFormat('es-ES', {
                        // style: 'currency',
                        // currency: 'USD',
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,

                        // These options are needed to round to whole numbers if that's what you want.
                        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
                    });

                    return "$" + formatter.format(value as number);
                },
                ArrayObject: () => <PopoverComponent
                    id="style_rules_definition_id"
                    label={<FontAwesomeIcon icon={faEdit}/>}
                    trigger="click" title=""
                    zIndex={header.zIndex}
                    placement="bottom">
                    <div style={{minWidth: "500px"}}>
                        <EditorComponent
                            readOnly
                            width={"100%"}
                            value={typeof value !== 'string' ? JSON.stringify(value, null, "\t") : value}
                            theme="dark_mitsuketa_tribute"
                            height="450px"
                        />
                    </div>
                </PopoverComponent>,
                Json: () => <PopoverComponent
                    id="style_rules_definition_id"
                    label={<FontAwesomeIcon icon={faEdit}/>}
                    trigger="click" title=""
                    zIndex={header.zIndex}
                    placement="bottom">
                    <div style={{minWidth: "500px"}}>
                        <EditorComponent
                            readOnly
                            width={"100%"}
                            value={typeof value !== 'string' ? JSON.stringify(value, null, "\t") : value}
                            theme="dark_mitsuketa_tribute"
                            height="450px"
                        />
                    </div>
                </PopoverComponent>,
                [SbxModelField.BOOLEAN]: () => Boolean(value) ? t('yes') : 'No',
                Boolean: () => Boolean(value) ? t('yes') : 'No',
                Fixed: () => typeof value === 'number' ? value.toFixed(2) : value,
                "List_provider": () => {
                    if ((value as ListProviderOption)?.label) {
                        return (value as ListProviderOption).label;
                    } else if (header.format_rules && header.format_rules.columns_labels?.length > 0) {
                        return getCompoundName({columns: header.format_rules.columns_labels, item: value});
                    } else {
                        if (typeof value === 'string') {
                            return value;
                        }
                    }

                    return '';
                },
                "Year_month": () => convertDateToYYYYMM(convertNumberDateToDate((value + '01'))),
                Custom: () => header.customShowColumn ? header.customShowColumn(value, index !== undefined && index >= 0 ? (index + (localPage - 1) * size) : undefined) : '',
                Color: () => <div className="rounded-circle " style={{
                    backgroundColor: checkValidColor(value as string) ? value as string : 'white',
                    height: '15px',
                    width: '15px'
                }}>
                </div>,
                Document: () => {
                    if (!value) {
                        return ""
                    }

                    async function onConfirmDownload(fileData: Content) {

                        // async function onDownload() {
                        const res: any = await getFile(fileData.key);
                        if (res.success) {
                            await downloadFileService(res.url, res.name);
                            // dispatch(actionsModal.closeModal({type: ModalTypes.CONFIRM}));
                        }
                        // }

                        // dispatch(actionsModal.openModal({
                        //   type: ModalTypes.CONFIRM,
                        //   onConfirm: onDownload,
                        //   title: <><FontAwesomeIcon icon={faFileDownload}/>{" " + "Download file"} </>,
                        //   message: <>{t("custom-message:warn-download")} {getContentFileName(fileData)}?</>
                        // }))
                    }

                    if (IsJsonString(value as string) && Array.isArray(JSON.parse(value as string))) {
                        const documents: Content[] = JSON.parse(value as string)


                        return <div className="d-flex flex-column gap-2"> {documents.map(document => (
                            <span key={document.key} onClick={() => onConfirmDownload(document)}
                                  className="underline pointer">
                {document.name && <FileTypeIcon name={document.name}/>} {getContentFileName(document)}
              </span>
                        ))}
                        </div>
                    }

                    return ""
                },
                comma_no_decimal: () => new Intl.NumberFormat('en-US').format(value as number),
                money_comma_decimal: () => new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: "USD"
                }).format(value as number),
                dot_no_decimal: () => new Intl.NumberFormat('es-ES', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                }).format(value as number),
                comma: () => new Intl.NumberFormat('en-US', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                }).format(value as number),
                comma_decimal: () => {
                    return new Intl.NumberFormat('en-US', {
                        style: 'decimal',
                    }).format(value as number);
                },
                two_decimal: () => {
                    return new Intl.NumberFormat('en-US', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                        style: 'decimal',
                    }).format(value as number);
                },
                money_dot_decimal: () => {
                    return new Intl.NumberFormat('es-CO', {
                        style: 'currency',
                        currency: 'COP',
                    }).format(value as number);
                    //Output: $12.000,00
                },
                percentage: () => value + "%",
                money_comma_no_decimal: () => new Intl.NumberFormat('en-US', {
                    style: 'currency',
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0
                }),
                money_dot_no_decimal: () => "$" + new Intl.NumberFormat('es-ES', {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                }).format(value as number),
                Image: () => {
                    let images = IsJsonString(value as string) ? JSON.parse(value as string) : value
                    if (images) {
                        images = Array.isArray(images) ? images : [images]
                    }

                    return <div>
                        {images.filter((item: string) => item).length > 0 ? <RenderImageViewer images={images}/> :
                            <span>{t("custom-message:no-images-message")}.</span>}
                    </div>
                },
                Link: () => {
                    // console.log("header", header)
                    return <span className="underline pointer text-link" onClick={() => {
                        if (header.value) {
                            if (isEncoded(header.value as string)) {
                                const decode = decodeURI(header.value as string)
                                const newUrl = decode.replaceAll("{row_value}", value as string)
                                openInNewTab(encodeURI(newUrl))
                            } else {

                                let url = header.value

                                if (url) {
                                    url = url.replaceAll("{row_value}", value as string)
                                }

                                openInNewTab(url as string)
                            }
                        }
                    }}>{value as string}</span>
                },
                TextColor: () => {
                    return <span
                        style={{color: checkIsValidHexColor(header.value as string) ? header.value as string : 'black'}}>{value as string}</span>
                },
                Whatsapp: () => {
                    return <span className="pointer" onClick={() => {
                        openInNewTab(`https://wa.me/57${value}`)
                    }}>
                        {value as string}  <Image src={wsIcon} alt="" style={{width: "15px", height: "15px"}}/>
                    </span>
                },
                Mailto: () => {
                    return <span>
                        {value as string} <FontAwesomeIcon icon={faEnvelope} className="pointer" color={"pointer"} onClick={() => {
                        window.open(`mailto:${value}`)
                    }} />
                    </span>
                }
            }

           
            return cases[header.type] ? cases[header.type]() : (Array.isArray(value) ? (value as string[]).join(", ") : value) as string;
        }

        return (Array.isArray(value) ? (value as string[]).join(", ") : value) as string;
    };

    const getRowValue = ({row, header, index}: { row: any, header: Column, index?: number }) => {
        if (header) {

            try {
                if (header?.column_reference?.compound_name && row[header.name]) {
                    return getCompoundName({columns: [header.column_reference] as any as ColumnsLabels[], item: row[header.name]}) ?? ""
                } else if (header?.value && row[header.name] && row[header.name].hasOwnProperty(header.value)) {
                    return getValue({value: row[header.name][header.value], header, index});
                } else if (row[header.name] && (!header.value || header.type === 'Reference')) {
                    return getValue({value: row[header.name], header, index});
                } else if (typeof row[header.name] === 'number' && row[header.name] === 0) {
                    return getValue({value: row[header.name], header, index});
                }

                return getValue({value: row[header.name], header, index});
            }catch (e) {
                return ""
            }

        }

        return ""

    };

    useEffect(() => {
        if (getColumns) {
            getColumns(columns);
        }
    }, [columns]);

    React.useEffect(() => {

        const newColumns = columnsData.filter(c => !excludeColumns.some(cx => cx === c.name)).map(column => ({
            ...column,
            isSort: false
        }))
        setColumns(newColumns);

        setCopyColumns(newColumns)
    }, [excludeColumns, columnsData]);

    const actionsMobileMenu = (row: any, rowIndex: number) => {
        return <UncontrolledButtonDropdown className="mini-menu custom">
            <DropdownToggle
                className="border-0">
                <FontAwesomeIcon icon={faEllipsisV}/>
            </DropdownToggle>
            <DropdownMenu className="position-fixed">
                <DropdownMenuHeader title={'Acciones'}/>
                {actions?.filter(({visible = true}) => visible)
                    .map((act, index) => !act.custom && (act.permission ?
                            <Permission key={index} permission={act.permission}>
                                {getActionButton(row, act, index, rowIndex, true)}
                            </Permission> : (
                                <React.Fragment key={uuidV4()}>
                                    {getActionButton(row, act, index, rowIndex, true)}
                                </React.Fragment>
                            )
                    ))}
            </DropdownMenu>
        </UncontrolledButtonDropdown>;
    };

    const getActionButton = (row: any, action: Action, index: number, rowIndex: number, isMobile = false) => {

        if (action.hasOwnProperty("visibleRow") && !action.visibleRow!(row)) {
            return <div/>;
        }

        if (isMobile) {
            return <DropdownItem
                disabled={action.disabled}
                key={index}
                title={action.title}
                className={'pl-4 pr-2 ' + `text-${action.type}`}
                onClick={() => {
                    action.onAction && action.onAction(row, rowIndex);
                }}>
                {action.label}
            </DropdownItem>;
        }


        return action?.custom && action.customComponent ? action.customComponent(row) :
            <Button
                disabled={action.disabled}
                size="sm"
                title={action.title}
                key={index}
                className="mx-1"
                onClick={() => action.onAction && action.onAction(row, rowIndex)}
                color={action.type}>{action.label}
            </Button>;
    };

    const renderActionsMenu = (row: any, rowIndex: number) => {
        switch (actionsType) {
            case 'list':
                return actionsMobileMenu(row, rowIndex);
            default:
                return actions?.filter(({visible = true}) => visible)
                    .map((action, index) => action.permission ? (
                        <Permission
                            key={index}
                            permission={action.permission}>
                            {getActionButton(row, action, index, rowIndex)}
                        </Permission>
                    ) : (
                        <React.Fragment key={uuidV4()}>
                            {getActionButton(row, action, index, rowIndex)}
                        </React.Fragment>
                    ));

        }
    };

    function alterExclude(columnName: string) {
        const exist = excludeColumns.some(cx => cx === columnName);
        const array = new Array(...excludeColumns);
        if (exist) {
            setExcludeColumns(array.filter(c => c !== columnName));
        } else {
            array.push(columnName);
            setExcludeColumns(array);
        }
    }

    const filterColumns = (f: string) => {
        return removeDuplicateFromArray([...columnsData, ...(hideColumnsReports ?? [])]?.filter(c => c.header.toLowerCase().indexOf(f.toLowerCase()) !== -1) ?? []);
    };

    React.useEffect(() => {

        if (hideColumnsReports && hideColumnsReports.length > 0) {
            setSelection(false)
            setExcludeColumns(prevState => [...prevState, ...hideColumnsReports.map(column => column.name)])
        }else{
            setExcludeColumns([])
        }

    }, [hideColumnsReports]);

    const total_items = totalData || newRows.length;

    const getColumnTotal = (column: Column) => {
        if (column.isTotalColumn) {
            let total = data.reduce((total, row) => {

                if (row[column.name]) {
                    total += row[column.name];
                }

                return total;
            }, 0);

            if (typeof total === 'number') {
                total = parseFloat(total.toFixed(2));
            }


            return getValue({value: total, header: column});
        }

        return null;
    };

    const isTableTotal = () => {
        return columns.some(column => column.isTotalColumn);
    };

    function downloadDataToFile(type: TypeFIle) {
        const text = convertTableRowsToCSVString(columns, rows);
        downloadTextToFile(text, type, convertDateToYYYYMMDD(new Date()));
    }

    React.useEffect(() => {
        // console.log("columnsData", columnsData)
        // console.log("rows", rows)

        if (removeEmptyColumns && newRows.length > 0) {
            setColumns([...copyColumns].filter(column => !newRows.every(row => {
                return !getRowValue({row, header: column}) || parseInt(getRowValue({
                    row,
                    header: column
                })) === 0 || (typeof getRowValue({row, header: column}) === 'string' && getRowValue({
                    row,
                    header: column
                }).includes("0.0"))
            })));
        }
    }, [removeEmptyColumns, newRows, copyColumns]);

    const sortColumn = ({column, asc = true}: { column: Column, asc?: boolean }) => {

        const nRows = [...newRows].sort((a, b) => {
            const aItem = getRowValue({row: a, header: column});
            const bItem = getRowValue({row: b, header: column});

            if (typeof aItem === 'string') {
                if (containsNumbers(aItem)) {
                    return asc ? aItem.localeCompare(bItem, undefined, {numeric: true}) : bItem.localeCompare(aItem, undefined, {numeric: true})
                } else {
                    return asc ? aItem.localeCompare(bItem) : bItem.localeCompare(aItem)
                }
            } else {
                return asc ? aItem - bItem : bItem - aItem;
            }
        })

        setNewRows(nRows);

        setColumns(currentColumns => {
            return currentColumns.map(nColumn => {
                if (nColumn.name === column.name) {
                    nColumn.isSort = !nColumn.isSort;
                }
                return nColumn;
            });
        });
    };

    const filterRowsByColumn = ({
                                    valueList,
                                    column,
                                    search
                                }: { valueList?: (string | number)[], column: Column, search?: string }) => {

        let nData = [...data];


        if (valueList) {
            nData = [...data].filter(row => valueList.includes((getRowValue({row, header: column}) ?? "").toString()))
        }

        if (search) {
            nData = ([...data].filter(row => (getRowValue({
                row,
                header: column
            }) ?? "").toString().toLowerCase().includes(search.toLowerCase())))
        }

        if (nData.length === data.length) {
            setNewRows(data);
        } else {
            setNewRows(nData);
        }

        setLocalPage(1);

    }


    const reportUpdateColumns = ({columns}: {columns: string[]}) => {
        // console.log("columns", columns)
        if (updateColumnsProps){
            updateColumnsProps({
                reportProp: 'sort',
                value: columns,
                column_name: ""
            })
        }

    }

    return (
        <div className={"mb-3 " + (shadow ? ` shadow-${shadow} ` : " card main-card")}>
            <div className={!shadow ? "card-body" : ""}>
                {loading ? (
                    <div className="d-flex justify-content-center">
                        <SpinnerComponent/>
                    </div>) : (
                    <>
                        {exportButtons.map((button, index) => (
                            <div className="d-flex justify-content-end mb-1" key={'export_button_' + index}>
                                <Button
                                    className="me-1"
                                    onClick={() => downloadDataToFile(button.type)}
                                    color={button.color}
                                    size="sm">
                                    <FontAwesomeIcon icon={faFileExport} className="me-1"/>
                                    {`${t('common:export')} ${button.type}`}
                                </Button>
                            </div>
                        ))}
                        {columnsSetting && (
                            <div>
                                <div className="d-flex align-items-center justify-content-end">
                                    <span className="mb-2">{t('columns')}:</span> {(
                                    <PopoverComponent
                                        zIndex={3000}
                                        label={<FontAwesomeIcon className="text-gray" icon={faEllipsisV}/>}
                                        trigger="click"
                                        title="Columns"
                                        placement="left"
                                        id={'pop-columns' + id}>
                                        <div
                                            style={{
                                                maxHeight: '80vh',
                                                overflowY: 'auto'
                                            }}
                                        >
                                            <FormGroup>
                                                <label>
                                                    {!selectAll ? t('select_all') : t('deselect_all')}
                                                    <Switch
                                                        className="ms-1"
                                                        defaultChecked={selectAll}
                                                        onChange={e => {
                                                            let newExcludeColumns = [...columns.map(c => c.name)];

                                                            if (hideColumnsReports && hideColumnsReports.length > 0) {
                                                                newExcludeColumns = newExcludeColumns.concat(hideColumnsReports.map(c => c.name))
                                                            }

                                                            if (updateColumnsProps) {
                                                                reportUpdateColumns({columns: e ? [] : newExcludeColumns})
                                                            }
                                                            setExcludeColumns(e ? [] : newExcludeColumns);
                                                        }}
                                                    />
                                                </label>
                                            </FormGroup>
                                            <FormGroup>
                                                <Input placeholder={t('search')} value={filter}
                                                       onChange={e => setFilter(e.target.value)}/>
                                            </FormGroup>
                                            {filterColumns(filter).map((c, index) => {
                                                const checked = !excludeColumns?.some(cx => cx === c.name);
                                                return (
                                                    <div key={c.name + index}>
                                                        <label className="m-0">
                                                            <input onChange={(event) => {
                                                                alterExclude(c.name)

                                                                const isChecked = event.target.checked;
                                                                // console.log("updateColumnsProps", updateColumnsProps)
                                                                // console.log("hideColumnsReports", hideColumnsReports)
                                                                // console.log("c", c)
                                                                if (updateColumnsProps) {

                                                                    let nColumns = [...columns].map(column => column.name);
                                                                    if (isChecked) {
                                                                        nColumns = [...nColumns, c.name]
                                                                    } else {
                                                                        nColumns = nColumns.filter(column => column !== c.name)
                                                                    }

                                                                    reportUpdateColumns({columns: removeDuplicateFromArray(nColumns)})


                                                                }
                                                            }} type="checkbox"
                                                                   checked={checked}/> {' ' + capitalize(c.header).replaceAll("-", " ").replaceAll("_", " ")}
                                                        </label>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </PopoverComponent>
                                )}
                                </div>
                            </div>
                        )}

                        {localFilter && <div className="form-search">
                            <input className="form-control"
                                   placeholder={t("common:search").concat("...")}
                                   type="search"
                                   onChange={e => setFilterLocal(e.target.value)}
                                   value={filterLocal}/>
                        </div>}
                        <Table className="table-hover table-bordered d-lg-table d-none" responsive
                               id={id ?? ''}>
                            <thead>
                            <tr>
                                {isTableTotal() &&
                                    <th style={{borderBottom: '1px solid #eeeeee'}} className="fw-bold"/>
                                }
                                {columns.map((column, index) => (
                                    <th style={{
                                        borderBottom: '1px solid #eeeeee',
                                        minWidth: "100px",
                                        backgroundColor: draggedColumnIndex === index ? "lightgray" : overColumnIndex === index ? "gray" : undefined,
                                        cursor: "move"
                                    }}
                                        className={(column.headerClassName ?? '')}
                                        draggable
                                        onDragStart={() => handleDragStart(index)}
                                        onDragEnter={() => handleDragEnter(index)}
                                        onDragEnd={handleDragEnd}

                                        key={`column_${column.isSort ? "sorteable_" : ""}${index}`}>

                                        <span className="d-flex align-items-center justify-content-between">
                                            <span className="d-flex">
                                            {column.header}
                                                <TableColumnFilterMenuComponent
                                                    sortColumn={({asc}) => sortColumn({asc, column})} column={column}
                                                    filterRowsByColumn={({valueList, search}) => filterRowsByColumn({
                                                        column,
                                                        valueList, search
                                                    })}
                                                    columnItems={removeDuplicateFromArray(data.map(row => getRowValue({
                                                        row,
                                                        header: column
                                                    })))}/>
                                            </span>

                                            {isReportTable && <span>
                                                <TableCustomColumnMenuComponent updateColumnsProps={updateColumnsProps}
                                                                                column={column} sbxModels={sbxModels}
                                                                                setColumnItems={setColumns}
                                                                                columnItems={removeDuplicateFromArray(data.map(row => getRowValue({
                                                                                    row,
                                                                                    header: column
                                                                                })))}/>
                                            </span>}


                                        </span>


                                        {/*{sortableTable &&*/}
                                        {/*<FontAwesomeIcon className="pointer" onClick={() => sortColumn(column)}*/}
                                        {/*                 icon={column.isSort ? faSortUp : faSortDown}/>}*/}
                                        {column.action && column.action}
                                    </th>
                                ))}
                                {actions && actions.length > 0 && (
                                    <th className={actionsColumnClass ?? ''}
                                        style={{borderBottom: '1px solid #eeeeee'}}>
                                        {actionsColumnLabel ?? ''}
                                    </th>
                                )}
                            </tr>
                            </thead>
                            <tbody>

                            {rows.map((row, row_index) => (
                                <tr key={`row_${row.id ?? uuidV4()}_${row_index}`}
                                    className={`${tableShading ? 'pointer shading py-5' : 'py-5'}`}>
                                    {isTableTotal() && <td/>}
                                    {columns.map((column, index) => (
                                        <td key={index} onClick={() => {
                                            if (column.className?.includes("underline") && row.url) {
                                                router.push(row.url)
                                            } else {
                                                if (rowAction) {
                                                    rowAction(row, column)
                                                }
                                            }
                                        }}
                                            className={`${column.className ?? ''}`} style={column.style ?? {}}>
                                            {getRowValue({row, header: column, index: row_index})}
                                        </td>
                                    ))}
                                    {actions && actions.length > 0 && (
                                        <td className="d-table-cell ">
                                            <div className={actionsColumnClass ?? 'd-flex justify-content-end'}>
                                                {renderActionsMenu(row, row_index)}
                                            </div>
                                        </td>
                                    )}

                                </tr>
                            ))}

                            {isTableTotal() && rows.length > 0 &&
                                <>
                                    <tr>
                                        <td className="fw-bold ">TOTAL</td>
                                        {columns.map((column, index) => (
                                            <td key={index}
                                                className={`fw-bold  ${column.headerClassName ?? column.type?.includes("money") ? 'text-end' : 'text-start'} `}
                                                style={column.style ?? {}}>
                                                {getColumnTotal(column)}
                                            </td>
                                        ))}
                                    </tr>
                                </>
                            }
                            </tbody>
                        </Table>

                        <div className="d-lg-none d-block custom px-3">
                            {rows.map((row, row_index) => {
                                return <Card key={row_index} className="my-2">
                                    <CardBody className="d-flex align-items-center">
                                        <div className="w-100">
                                            {columns.map((column, index) => (
                                                <span key={index}>
                        {getRowValue({row, header: column, index: row_index}) &&
                            <div className="m-0" onClick={() => rowAction ? rowAction(row, column) : undefined}>
                                <b>{column.header}:</b> {getRowValue({row, header: column, index: row_index})}
                            </div>
                        }
                      </span>))}</div>
                                        {actions && (actionsMobileMenu(row, row_index))}</CardBody></Card>
                            })}
                        </div>
                    </>
                )}
                {!total_items && !loading && (
                    <div className="text-center py-5">
                        {t('empty_table')}
                    </div>)}
                {(!!total_items && pagination) &&
                    <div className="d-flex justify-content-between  align-items-center px-2">
                        <div>
                            <small
                                className="text-gray">{(size * localPage) > total_items ? total_items : (size * localPage)} of {total_items}</small>
                        </div>
                        <Pagination disabled={loading}
                                    current={localPage}
                                    onShowSizeChange={(p, s) => {
                                        onShowSizeChange && onShowSizeChange(p, s);
                                        setSize(s);
                                    }}
                                    pageSizeOptions={[DEFAULT_SIZE.toString(), '30', '60', '100', '500', '1000']}
                                    showSizeChanger={total_items > DEFAULT_SIZE && showSizeChanger}
                                    onChange={page => useLocalPage ? setLocalPage(page) : dispatch(actionHistory.changePage(page))}
                                    pageSize={size}
                                    total={total_items}/>
                    </div>}
            </div>
        </div>
    );
};

export default CustomTableComponent;

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faSpinner, faTimes} from "@fortawesome/free-solid-svg-icons";
import {Button} from "reactstrap";
import ModalComponent from "./ModalComponent/ModalComponent";
import React from "react";
import {useDispatch, useSelector} from "react-redux";
import {actionsModal, ModalTypes} from "../../../store/Modal/Slice";
import useTranslate from "../../../hooks/useTranslate";

interface IProps {
  data: IPropsConfirmModal;
  open: boolean;
}

export interface IPropsConfirmModal {
  type: ModalTypes.CONFIRM;
  onConfirm: (data?: any) => any;
  onClose?: (data?: any) => any;
  message: React.ReactNode;
  info?: any;
  title?: React.ReactNode;
  body?: React.ReactNode;
  state?: string
  closeModal?: boolean;
  formId?: string
}

const ConfirmationModal = (
  {open, data: {onConfirm, message, info, title, state = "", body, closeModal, formId, onClose}}: IProps) => {
  const dispatch = useDispatch();
  const stateReducers: any = useSelector(state => state);

  const {t} = useTranslate("common");

  const loading = Object.keys(stateReducers).find(key => {
    return stateReducers[key]?.state === state || stateReducers[key]?.mfaStates === state;
  });

  const toggle = () => {
    if (onClose) {
      onClose(info);
    }
    dispatch(actionsModal.closeModal({type: ModalTypes.CONFIRM}))
  }

  return (
    <ModalComponent
      title={title}
      footer={
        <>
          <Button
            type={formId ? "submit" : "button"}
            form={formId}
            disabled={!!loading}
            onClick={() => {
              onConfirm(info)
              closeModal && dispatch(actionsModal.closeModal({type: ModalTypes.CONFIRM}))
            }}
            color="primary" size="sm">
            <FontAwesomeIcon icon={loading ? faSpinner : faCheck} spin={!!loading}/>{" " + t("confirm")}
          </Button>
          <Button
            disabled={!!loading}
            onClick={toggle}
            color="light" size="sm">
            <FontAwesomeIcon icon={faTimes}/> {" " + t("cancel")}
          </Button>
        </>
      }
      isOpen={open}
      toggle={() => !loading && toggle()}>
      <div className={formId ? "" : "text-center"}>
        {message}
      </div>
      {body}
    </ModalComponent>
  )
}


export default ConfirmationModal;

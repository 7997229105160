import * as React from 'react';
import {Dispatch, SetStateAction, useRef, useState} from 'react';
import {Column} from "../CustomTableComponent";
import {Dropdown, MenuProps, Popover} from "antd";
import {faCircleMinus, faCirclePlus, faEllipsisV} from "@fortawesome/free-solid-svg-icons";
import useTranslate from "../../../hooks/useTranslate";
import ButtonComponent from "../ButtonComponent";
import {ModelsResponse} from "../../../types/Sbx";
import {SubMenuType} from "antd/es/menu/hooks/useItems";
import {capitalize} from "../../../utils";
import {Find} from "sbxcorejs";

type Props = {
    column: Column
    columnItems: any[]
    setColumnItems: Dispatch<SetStateAction<Column[]>>
    sbxModels?: ModelsResponse[],
    updateColumnsProps?: ({reportProp, column_name, value, subProp}: {
        column_name: string,
        value: any, subProp?: string,
        reportProp: 'sort' | 'custom_column' | 'columns_to_summarize'
    }) => void;
};


const specialCharacters = ['$', '%', ',', '.']
const TableCustomColumnMenuComponent = ({
                                            setColumnItems,
                                            column,
                                            columnItems,
                                            updateColumnsProps,
                                            sbxModels
                                        }: Props) => {



    const {t} = useTranslate('common');
    const [disableNumberOptions, setDisableNumberOptions] = useState(false)
    const [openDropdown, setOpenDropdown] = useState(false)
    const linkRef = useRef<null | HTMLInputElement>(null)

    const toggleDropdown = () => {
        setOpenDropdown(prevState => !prevState)
    }

    React.useEffect(() => {
        setDisableNumberOptions(columnItems.filter(element => element).every(element => typeof element === 'string' && !specialCharacters.some(character => element.includes(character))))
    }, [columnItems]);

    React.useEffect(() => {

        if (column?.value && linkRef.current) {
            linkRef.current!.value = column.value
        }

        

    }, [column]);

    const updateColumnProp = ({prop, value, reportProp, subProp, toggle = true}: {
        prop: keyof Column,
        subProp?: string
        value: any,
        reportProp: 'sort' | 'custom_column' | 'columns_to_summarize',
        toggle?: boolean
    }) => {
        if (toggle) {
            toggleDropdown()
        }
        setColumnItems(prevState => prevState.map((item: Column) => {

            if (item.name === column.name) {
                return {
                    ...item,
                    [prop]: value
                }
            }

            return item
        }))

        if (updateColumnsProps && reportProp) {
            updateColumnsProps({column_name: column.name, value, reportProp, subProp})
        }
    }

    const items = (t: (str: string) => string) => {


        let colorList: string[] = []

        let options: MenuProps['items'] = [
            {
                key: '1',
                type: 'group',
                label: t("options"),
                children: [
                    {
                        key: '1-1',
                        disabled: disableNumberOptions,
                        label: `${column.isTotalColumn ? t("remove") : ""} ${t("summarize")} ${t("column")}`,
                        onClick: () => {
                            updateColumnProp({
                                prop: 'isTotalColumn',
                                value: !column.isTotalColumn,
                                reportProp: "columns_to_summarize"
                            })
                        }
                    },
                    {
                        key: '1-2',
                        disabled: !sbxModels,
                        label: <Popover placement="right" title={""} content={

                            <div className="p-2" style={{width: '20em'}}>
                                <div className="d-flex align-items-center">
                                    <span>Link: </span>
                                    <ButtonComponent label={""} color={"default"} icon={faCirclePlus} iconColor={"blue"}
                                                     onClick={() => {
                                                         if (linkRef.current) {
                                                             linkRef.current.value += "{row_value}"
                                                         }
                                                     }} tooltipLabel={t("custom-message:make_click_add_row_value")}/>
                                </div>

                                <div className="d-flex gap-2 align-items-center">
                                    <input type="text" ref={linkRef} className="form-control" defaultValue={linkRef.current!?.value || ""}
                                           placeholder={"www.mylink.com"}/>
                                    <ButtonComponent label={""} outline className="p-2" onClick={() => {
                                        updateColumnProp({
                                            prop: 'type',
                                            value: linkRef.current?.value,
                                            subProp: "Link",
                                            reportProp: "custom_column"
                                        })

                                        linkRef.current!.value = ""
                                    }} icon={faCirclePlus}/>

                                    {column.value &&
                                        <ButtonComponent label={""} outline className="p-2" color={"danger"}
                                                         onClick={() => {
                                                             linkRef.current!.value = ""
                                                             updateColumnProp({
                                                                 prop: 'type',
                                                                 value: "",
                                                                 reportProp: "custom_column"
                                                             })
                                                         }} icon={faCircleMinus}/>}
                                </div>
                            </div>} trigger="hover">

                            <div>{`${t("add")} ${t("link")}`}</div>
                        </Popover>
                    },
                    {
                        key: "1-3",
                        label: `${t("remove")} ${t("format")}`,
                        disabled: !column.type,
                        onClick: () => {
                            updateColumnProp({prop: 'type', value: "", reportProp: "custom_column"})
                        }
                    }
                ],
            },
            {
                key: '2',
                // type: 'group',
                label: `${t("associate")} ${t("to_1")} ${t("sbx_model")}`,
                children: []
            },
            {
                key: '3',
                label: `${t("custom_format")} ${t("report:numeric")}`,
                disabled: disableNumberOptions,
                children: [
                    {
                        key: "money_comma_decimal",
                        label: "$32,000",
                        onClick: () => {
                            updateColumnProp({prop: 'type', value: "money_comma_decimal", reportProp: "custom_column"})
                        }
                    },
                    {
                        key: "comma_decimal",
                        label: "32,000,02",
                        onClick: () => {
                            updateColumnProp({prop: 'type', value: "comma_decimal", reportProp: "custom_column"})
                        }
                    },
                    {
                        key: "money_dot_decimal",
                        label: "$12.000,00",
                        onClick: () => {
                            updateColumnProp({prop: 'type', value: "money_dot_decimal", reportProp: "custom_column"})
                        }
                    },
                    {
                        key: "two_decimal",
                        label: "1,234.57",
                        onClick: () => {
                            updateColumnProp({prop: 'type', value: "two_decimal", reportProp: "custom_column"})
                        }
                    },
                    {
                        key: "dot_no_decimal",
                        label: "32.000",
                        onClick: () => {
                            updateColumnProp({prop: 'type', value: "dot_no_decimal", reportProp: "custom_column"})
                        }
                    },
                    {
                        key: "comma_no_decimal",
                        label: "32,000",
                        onClick: () => {
                            updateColumnProp({prop: 'type', value: "comma_no_decimal", reportProp: "custom_column"})
                        }
                    },
                    {
                        key: "percentage",
                        label: "32%",
                        onClick: () => {
                            updateColumnProp({prop: 'type', value: "percentage", reportProp: "custom_column"})
                        }
                    }
                    ,
                    {
                        key: "remove",
                        label: `${t("remove")} ${t("format")}`,
                        onClick: () => {
                            updateColumnProp({prop: 'type', value: "", reportProp: "custom_column"})
                        }
                    }
                ],
            },
            {
                key: '4',
                label: `${t("custom_format")} ${t("text")}`,
                children: [
                    {
                        key: "document",
                        label: t("document"),
                        disabled: !disableNumberOptions,
                        onClick: () => {
                            updateColumnProp({prop: 'type', value: "Document", reportProp: "custom_column"})
                        }
                    },
                    {
                        key: "color",
                        label: <Popover placement="right" title={""} content={
                            <div style={{width: '10em'}}>
                                <input type="color" className="form-control" defaultValue={column.value || ""}
                                       onChange={(event) => {
                                           let color = event.target.value
                                           colorList.unshift(color)
                                           setTimeout(() => {

                                               if (colorList.length > 0) {
                                                   updateColumnProp({
                                                       prop: 'type',
                                                       subProp: "TextColor",
                                                       value: colorList[0],
                                                       toggle: false,
                                                       reportProp: "custom_column"
                                                   })
                                                   colorList = []
                                               }

                                           }, 1000)
                                       }}/>
                            </div>
                        }>
                            <div>{`${t("report:select")} ${t("color")}`}</div>
                        </Popover>,
                    },
                    {
                        key: "remove-text",
                        label: `${t("remove")} ${t("format")}`,
                        disabled: !column.value,
                        onClick: () => {
                            updateColumnProp({prop: 'type', value: "", reportProp: "custom_column"})
                        }
                    }
                ],
            }
        ]

        if (sbxModels && sbxModels!?.length > 0 && options) {

            (options[1] as SubMenuType).children = sbxModels.map(sbxModel => {
                return {
                    key: sbxModel.id,
                    label: capitalize(sbxModel.name),
                    children: [{
                        name: "_KEY",
                        id: "0" + sbxModel.name
                    }, ...sbxModel.properties].map((property, index) => {

                        const query = new Find(sbxModel.name, 0)
                        query.andWhereIsEqualTo(property.name, "{row_value}")
                        const compiledQuery = query.compile()

                        const params = encodeURI(JSON.stringify(compiledQuery.where))

                        const queryUrl = `https://sbxcloud.com/#!/app/data/129/model/${sbxModel.id}/list?qr=${params}`


                        return {
                            key: property.id,
                            label: capitalize(property.name),
                            onClick: () => {
                                updateColumnProp({
                                    prop: 'type',
                                    value: queryUrl,
                                    subProp: "Link",
                                    reportProp: "custom_column"
                                })
                            }
                        }
                    })
                }
            })

            if ((options[1] as SubMenuType)?.children && column.value) {
                (options[1] as SubMenuType).children.unshift({
                    key: "remove_association",
                    label: `${t("remove")} ${t("association")}`,
                    onClick: () => {
                        updateColumnProp({
                            prop: 'type',
                            value: "",
                            reportProp: "custom_column"
                        })
                    }
                })
            }
        } else {
            if (sbxModels?.length === 0 && options) {
                options = options.filter(option => option?.key !== '2')
            }
        }


        return options

    }

    return <Dropdown open={openDropdown} menu={{items: items(t), onMouseLeave: () => setOpenDropdown(false)}}>
        <ButtonComponent label={""} icon={faEllipsisV} color={"default"} className="mx-2"
                         onClick={() => setOpenDropdown(prevState => !prevState)}/>
    </Dropdown>
};

export default TableCustomColumnMenuComponent
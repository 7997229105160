import React, {useReducer, useState} from "react";
import useAsyncEffect from "../../hooks/useAsyncEffect";
import {Content, FolderContent} from "../../types/Folder/Content";
import SpinnerComponent from "./SpinnerComponent";
import {Col, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faFileAlt,
  faFileDownload,
  faFileExcel,
  faFilePdf,
  faFileWord,
  faFolder,
  faSearch
} from "@fortawesome/free-solid-svg-icons";
import {filterArray, getContentFileName} from "../../utils";
import {useDispatch} from "react-redux";
import {actionsModal, ModalTypes} from "../../store/Modal/Slice";
import {AccountStates} from "../../store/Account/Types";
import {actionsAccount} from "../../store/Account/Slice";
import {downloadFileService, getFile, getFileData} from "../../services/UtilsService";

const sizeName = 15;

interface IProps {
  parentKey: string;
}

enum Type {
  SET_DATA,
  CHANGE_STATE
}

type Types = { type: Type.SET_DATA, payload: FolderContent } | { type: Type.CHANGE_STATE, payload: boolean };

const initialState: {
  data?: FolderContent,
  state: boolean
} = {
  state: false
}

function reducer(state = initialState, action: Types) {
  switch (action.type) {
    case Type.SET_DATA:
      return {
        ...state,
        state: false,
        data: action.payload
      }
    case Type.CHANGE_STATE:
      return {
        ...state,
        state: action.payload
      }
    default:
      return state;
  }
}

const actions = {
  getFolder: (dispatch: React.Dispatch<Types>, key: string) => {
    actions.changeState(dispatch, true);
    getFileData(key).then(res => {
      if (res.success) {
        dispatch({type: Type.SET_DATA, payload: res.item})
      }
    });
  },
  changeState: (dispatch: React.Dispatch<Types>, state: boolean) => {
    dispatch({type: Type.CHANGE_STATE, payload: state});
  }
}

export function FileTypeIcon({name}: { name: string }) {
  const type = ("" + (name ?? "").split(".").pop()).toLowerCase();
  let icon, color: string;
  switch (type) {
    case "pdf":
      icon = faFilePdf;
      color = "danger";
      break;
    case "docx":
      icon = faFileWord;
      color = "blue";
      break;
    case "csv":
    case "xlsx":
      icon = faFileExcel;
      color = "success";
      break;
    default:
      icon = faFileAlt;
      color = "gray"
      break;
  }

  return <FontAwesomeIcon icon={icon} size="2x" className={"text-" + color}/>
}

const ContentEngine = ({parentKey}: IProps) => {
  const [{state, data}, dispatchEvent] = useReducer(reducer, initialState);
  const [filter, setFilter] = useState("");
  const dispatch = useDispatch();

  useAsyncEffect(async () => {
    actions.getFolder(dispatchEvent, "")
  }, []);

  function onConfirmDownload(fileData: Content) {

    async function onDownload() {
      dispatch(actionsAccount.changeState(AccountStates.ACCOUNT_PENDING));
      const res: any = await getFile(fileData.key);
      if (res.success) {
        await downloadFileService(res.url, res.name);
        dispatch(actionsAccount.changeState(AccountStates.ACCOUNT_RESOLVED));
        dispatch(actionsModal.closeModal({type: ModalTypes.CONFIRM}));
      }
    }

    dispatch(actionsModal.openModal({
      type: ModalTypes.CONFIRM,
      onConfirm: onDownload,
      state: AccountStates.ACCOUNT_PENDING,
      title: <><FontAwesomeIcon icon={faFileDownload}/>{" " + "Download file"} </>,
      message: <>¿Are you sure to download file?</>
    }))
  }

  if (state) {
    return (
      <div className="text-center">
        <div className="d-flex justify-content-center">
          <SpinnerComponent/>
        </div>
        <p>Loading folders...</p>
      </div>
    )
  }

  const isParent = data?.folder.key === parentKey;

  return (
    <div className="">
      <div className="d-flex justify-content-between align-items-center p-0 m-0">
        <button onClick={() => actions.getFolder(dispatchEvent, data?.folder?.parent_key || "")}
                disabled={isParent}
                className="btn btn-link m-0">
          <FontAwesomeIcon icon={faArrowLeft}/>
        </button>
        <div className="d-flex align-items-center w-50">
          <input placeholder="Example: Projects"
                 value={filter}
                 onChange={e => setFilter(e.target.value)}
                 type="text"
                 className="form-control custom-form-control-search "/>
          <FontAwesomeIcon icon={faSearch}/>
        </div>
      </div>
      <hr/>
      <Row className="overflow-auto vh-50" >
        {filterArray((data?.contents ?? []), filter)
          .sort((a: Content, b: Content) => (`${a.mimetype || ""}`).localeCompare(`${b.mimetype || ""}`))
          .map((fileData: Content, index) => {
            const isFolder = !fileData.mimetype;
            let name = getContentFileName(fileData as Content)
            // const name = fileData.name.slice(0, sizeName) + (fileData.name.length > sizeName ? "..." : "");
            name = name.slice(0, sizeName) + (name.length > sizeName ? "..." : "");
            return (
              <Col key={index} lg={2} md={3} sm={6} xs={6} className="text-center mt-2">
                <div
                  title={!isFolder ? "Double click to download file" : ""}
                  onDoubleClick={() => onConfirmDownload(fileData)}
                  onClick={() => isFolder && actions.getFolder(dispatchEvent, fileData.key)}
                  className="pointer shadow--hover text-center p-2">
                  {isFolder ? (
                    <FontAwesomeIcon
                      className={"text-orange"}
                      size="2x"
                      icon={faFolder}/>
                  ) : (
                    <FileTypeIcon name={fileData.name}/>
                  )}
                </div>
                {isFolder ? (
                  <p title={fileData.name} className="m-0">{name}</p>
                ) : (
                  <span title={fileData.name} className="text-blue pointer">{name}</span>
                )}
              </Col>
            )
          })}
      </Row>
    </div>
  )
}

export default ContentEngine;

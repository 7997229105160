import React, {CSSProperties} from 'react';
import {Button} from 'reactstrap';
import {IconProp, SizeProp} from '@fortawesome/fontawesome-svg-core';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Tooltip} from "antd";

type Props = {
    loading?: boolean;
    disabled?: boolean
    color?: 'primary' | 'danger' | 'warning' | 'success' | 'info' | 'default' | "link" | "secondary"
    label: string
    icon?: IconProp
    className?: string
    outline?: boolean
    size?: 'lg' | 'sm' | ''
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    style?: CSSProperties
    sizeIcon?: SizeProp
    iconColor?: string
    tooltipLabel?: string
}

const ButtonComponent = ({
                             color = 'primary',
                             disabled,
                             label,
                             loading,
                             icon,
                             outline = false,
                             className = '',
                             size = '',
    tooltipLabel = '',
                             onClick,
                             style = {},
                             iconColor, sizeIcon
                         }: Props) => {
    return <Tooltip title={tooltipLabel ?? label ?? ""}>
        <Button color={color} disabled={disabled} style={style} outline={outline} onClick={onClick ?? undefined}
                className={`${className} d-flex align-items-center`} size={size}>
            {loading ? <FontAwesomeIcon icon={faSpinner} pulse className="me-1"/> :
                icon && <FontAwesomeIcon className="mx-1" icon={icon} size={sizeIcon} color={iconColor ?? undefined}/>}
            {label} {" "}
        </Button>
    </Tooltip>
};

export default ButtonComponent;

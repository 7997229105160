import React, {createContext, FormEvent, useContext, useEffect, useReducer} from "react";
import useTranslate from "../../../../hooks/useTranslate";
import useAsyncEffect from "../../../../hooks/useAsyncEffect";
import {State} from "../../../../types/State";
import {SbxAlternativeModelField, SbxModelField} from "../../../../types/Sbx";
import {findByModel, insertModelRow, updateModelRow} from "../../../../services/backend/SbxService";
import {capitalize, convertDateToNumberDate, convertNumberDateToDate} from "../../../../utils";
import {getProviderByIdWidthOptions, getProviderBySbxQuery} from "../../../../services/backend/DataProviderService";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {OptionsType} from "react-select";
import {Col, FormGroup, Label, Row} from "reactstrap";
import LargeTextComponent from "../../FieldComponents/LargeTextComponent";
import SmallTextComponent from "../../FieldComponents/SmallTextComponent";
import DateComponent from "../../FieldComponents/DateComponent";
import NumberComponent from "../../FieldComponents/NumberComponent";
import SelectComponent from "../../FieldComponents/SelectComponent";
import {Switch} from "antd";
import PhoneComponent from "../../FieldComponents/PhoneComponent";
import EmailComponent from "../../FieldComponents/EmailComponent";
import DocumentComponent from "../../FieldComponents/DocumentComponent";
import {IPropsModelModal} from "./index";

export type FieldValType = TypeOne | TypeTwo | TypeThree | TypeFour | TypeFive | TypeSix | TypeSeven | TypeEight;


interface DefaultFields {
  field: string;
  label?: string;
  required?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  placeholder?: string;
}


interface TypeOne extends DefaultFields {
  value: string;
  type: SbxModelField.STRING | SbxModelField.TEXT | SbxAlternativeModelField.PHONE_STRING | SbxAlternativeModelField.EMAIL | SbxAlternativeModelField.DOCUMENT;
}

interface TypeFour extends DefaultFields {
  value: string;
  type: SbxModelField.REFERENCE;
  fieldLabel: string;
  reference: string;
}

interface TypeTwo extends DefaultFields {
  value: number;
  type: SbxModelField.INT | SbxModelField.FLOAT;
}

interface TypeFive extends DefaultFields {
  value: boolean;
  type: SbxModelField.BOOLEAN;
}

interface TypeSix extends DefaultFields {
  value: number | null;
  type: SbxAlternativeModelField.NUMBER_DATE;
}

interface TypeSeven extends DefaultFields {
  value: string;
  provider: number;
  fieldLabel: string;
  type: SbxAlternativeModelField.LIST_PROVIDER_SBX;
}

interface TypeEight extends DefaultFields {
  value: string;
  provider: number;
  type: SbxAlternativeModelField.LIST_PROVIDER_DB;
}

interface TypeThree extends DefaultFields {
  type: SbxModelField.DATE;
  value: Date;
}


const initialState: IState = {
  state: State.IDLE,
  fieldOptions: {},
  values: {}
}

type Option = { [key: string]: OptionsType<any> };

interface IState {
  fieldOptions: Option;
  values: any;
  state: State;
}


function getLabel(opt: any, field: string) {
  if (field.includes(",")) {
    let val = "";
    field.split(",").forEach(key => {
      val += ` ${opt[key]}`
    });
    return val;
  }
  return opt[field]
}

const slice = createSlice({
  name: "modelModal",
  initialState,
  reducers: {
    changeValue: (state, action: PayloadAction<{ value: any, fieldKey: string }>) => {
      state.values = Object.assign(state.values, {[action.payload.fieldKey]: action.payload.value});
    },
    setOptionsValue: (state, action: PayloadAction<Option>) => {
      state.fieldOptions = Object.assign(state.fieldOptions, action.payload);
    },
    changeState: (state, action: PayloadAction<State>) => {
      state.state = action.payload;
    }
  }
});
const actions = slice.actions;
const Context = createContext<{
  onChange: (value: any, key: string) => void;
  fieldOptions: Option;
}>({
  onChange: () => null,
  fieldOptions: {}
})


export interface UpsertProps extends Omit<IPropsModelModal, "header" | "title" | "type" | "key"> {
  onLoading: (state: State) => void;
  keyMap?: string
}

const UpsertModel = (props: UpsertProps) => {
  const {fields, rowModel, keyMap: key, onFinish, row, callback, onLoading} = props;
  const {t} = useTranslate("common");
  const [{state, values, fieldOptions}, dispatchLocal] = useReducer(slice.reducer, initialState);


  useEffect(() => onLoading(state), [state]);

  useAsyncEffect(async () => {
    if (fields.length) {
      dispatchLocal(actions.changeState(State.PENDING));
      for await (let item of fields) {
        dispatchLocal(actions.changeValue({value: item.value, fieldKey: item.field}));
        switch (item.type) {
          case SbxModelField.REFERENCE:
            const fieldReference = item.fieldLabel;
            const resReference = await findByModel({row_model: item.reference, page: 1, size: 1000});
            if (resReference.success && resReference.items) {
              dispatchLocal(actions.setOptionsValue({
                [item.field]: resReference.items.map((opt: any) => ({
                  label: capitalize(getLabel(opt, fieldReference)),
                  value: opt._KEY
                }))
              }))
            }
            break;
          case SbxAlternativeModelField.LIST_PROVIDER_DB:
            const resListProviderBD = await getProviderByIdWidthOptions(item.provider);
            if (resListProviderBD.success && resListProviderBD.item && resListProviderBD.item?.options) {
              dispatchLocal(actions.setOptionsValue({
                [item.field]: resListProviderBD.item.options.map((opt: any) => ({
                  label: capitalize(opt.label),
                  value: opt.value ?? ""
                }))
              }))
            }
            break;
          case SbxAlternativeModelField.LIST_PROVIDER_SBX:
            const fieldListProvideSBX = item.fieldLabel;
            const resListProviderSBX = await getProviderBySbxQuery(item.provider);
            if (resListProviderSBX.success && resListProviderSBX.items) {
              dispatchLocal(actions.setOptionsValue({
                [item.field]: resListProviderSBX.items.map((opt: any) => ({
                  label: capitalize(getLabel(opt, fieldListProvideSBX)),
                  value: opt._KEY ?? ""
                }))
              }))
            }
            break;
        }
      }
      dispatchLocal(actions.changeState(State.RESOLVED));
    }
  }, [fields]);

  async function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();

    dispatchLocal(actions.changeState(State.PENDING));
    let res;
    let type: "create" | "update" = "create";
    if (key) {
      res = await updateModelRow([{...row, ...values, _KEY: key}], rowModel);
      type = "update";
    } else {
      res = await insertModelRow([{...row, ...values}], rowModel);
    }

    if (res.success) {
      if (callback) {
        await callback({...row}, values, type);
      }
      if (onFinish) onFinish(res, {...row, ...values});
      dispatchLocal(actions.changeState(State.RESOLVED));
    } else {
      dispatchLocal(actions.changeState(State.REJECTED));
    }
  }

  return (
    <form id={rowModel} onSubmit={onSubmit}>
      <Context.Provider value={{
        onChange: (value, fieldKey) => dispatchLocal(actions.changeValue({value, fieldKey})),
        fieldOptions
      }}>
        <Row>
          {fields.map(field => (
            <Col key={field.field} lg={6} sm={12}>
              <FormGroup>
                {field.label && (<Label className="me-2" htmlFor={field.field}>{t(field.label)}</Label>)}
                <FieldInput {...field} disabled={field.disabled || state === State.PENDING}
                            value={values[field.field] ?? ""}/>
              </FormGroup>
            </Col>
          ))}
        </Row>
      </Context.Provider>
    </form>
  )
}


const FieldInput = (props: FieldValType): any => {
  const {fieldOptions, onChange: onChangeLocal} = useContext(Context);
  const onChange = (value: string | number | Date | boolean | null) => {
    onChangeLocal(value, props.field);
  }
  const defaultProps = {
    required: props.required,
    readonly: props.readonly,
    disabled: props.disabled,
    id: props.field,
    placeholder: props.placeholder
  }
  switch (props.type) {
    case SbxModelField.TEXT:
      return <LargeTextComponent {...defaultProps} name={props.field} value={props.value} onChange={onChange}/>
    case SbxModelField.STRING:
      return <SmallTextComponent {...defaultProps} name={props.field} value={props.value} onChange={onChange}/>
    case SbxModelField.DATE:
      return <DateComponent {...defaultProps} value={props.value} onChange={onChange}/>
    case SbxModelField.FLOAT:
    case SbxModelField.INT:
      return <NumberComponent  {...defaultProps} name={props.field} value={props.value}
                               onChange={onChange}/>
    case SbxModelField.REFERENCE:
    case SbxAlternativeModelField.LIST_PROVIDER_DB:
    case SbxAlternativeModelField.LIST_PROVIDER_SBX:
      const options = fieldOptions[props.field] ?? [];
      return <SelectComponent {...defaultProps} name={props.field} options={options}
                              sortOptions={false}
                              onChange={e => onChange(e.value ?? null)}
                              value={options.find(opt => opt.value === props.value)}/>
    case SbxModelField.BOOLEAN:
      return <Switch {...defaultProps} checked={props.value} onChange={e => onChange(Boolean(e))}/>
    case SbxAlternativeModelField.PHONE_STRING:
      return <PhoneComponent {...defaultProps} name={props.field} value={props.value} onChange={onChange}/>
    case SbxAlternativeModelField.NUMBER_DATE:
      return <DateComponent {...defaultProps} value={props.value ? convertNumberDateToDate(props.value) : null}
                            onChange={date => date ? onChange(convertDateToNumberDate(date)) : null}/>
    case SbxAlternativeModelField.EMAIL:
      return <EmailComponent {...defaultProps} name={props.field} value={props.value} onChange={onChange}/>
    case SbxAlternativeModelField.DOCUMENT:
      return <DocumentComponent {...defaultProps} name={props.field} value={props.value} onChange={onChange}/>
  }
}

export default UpsertModel;
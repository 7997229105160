import React, {FormEvent, Fragment, useEffect, useRef, useState} from "react";
import useTranslate from "../../hooks/useTranslate";
import {AnyData} from "../../types/AnyData";
import {conditions, ConditionType, Container, FieldTypesRule, Operator, RuleCondition, ValuesData} from "./Types";
import {Card, CardBody, CardHeader, Col, Label, Row} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCheck, faPlus, faTimes} from "@fortawesome/free-solid-svg-icons";
import FieldValidator from "./FieldValidator";
import {
  addRule,
  existComparator,
  existCondition,
  existField,
  generateValues,
  getSize,
  onChangeValues,
  onDeleteRule,
  sortContainers
} from "./RuleUtils";
import {capitalize} from "../../utils";
import {Switch} from "antd";


export interface IPropsRule extends AnyData {
  formId?: string;
  rules: RuleCondition[];
  containers: Container[];
  onSuccess?: (rules: RuleCondition[]) => void;
}

const RuleGenerator = ({containers, onSuccess, formId, rules: rulesValues}: IPropsRule) => {
  
  const [values, setValues] = useState<ValuesData>({});
  const [rules, setRules] = useState<RuleCondition[]>([]);
  const initialValues = useRef<ValuesData>();
  const {t} = useTranslate("common");
  const existC = existCondition(containers);
  const existCm = existComparator(containers);
  const existF = existField(containers);
  
  useEffect(() => {
    setRules(rulesValues);
  }, [rulesValues])
  
  useEffect(() => {
    let valuesT: ValuesData = generateValues(containers, values);
    setValues(valuesT);
    if (!initialValues.current) {
      initialValues.current = valuesT;
    }
  }, [containers])
  
  function onSubmit(e: FormEvent<HTMLFormElement>) {
    e.preventDefault();
    setRules(addRule(rules, values));
    if (initialValues.current) {
      setValues(initialValues.current);
    }
  }
  
  function onChange(e: any, field: Container, index: number) {
    setValues(v => ({...v, [index]: {...v[index], value: e}}));
    if (field.onChange) {
      field.onChange(e);
    }
  }
  
  const fieldsSorted = sortContainers(containers);
  
  return (
    <Card title={t("rule_title")}>
      <CardBody>
        {(existC && existF && existCm) ? (
          <div>
            <form id={formId ?? "rule_form_id"} onSubmit={onSubmit}>
              <Row>
                {fieldsSorted.map((field, index) => {
                  let size = getSize(containers, field);
                  const value = values[index]?.value
                  return (
                    <Col key={index} sm={12} lg={size}>
                      <Label>{field.label}&nbsp;</Label>
                      <FieldValidator
                        {...field}
                        readonly={fieldsSorted.reduce((bool: boolean, f, i, array) => {
                          if ((field as any).isValidator) {
                            const f = array.find(field2 => field2.type === FieldTypesRule.CONDITION);
                            if (f) {
                              const index = array.indexOf(f);
                              const val = values[index]?.value;
                              bool = val?.value === ConditionType.NO_EXIST || val?.value === ConditionType.EXIST;
                            } else {
                              bool = false;
                            }
                          }
                          return bool;
                        }, false)}
                        value={value}
                        onChange={(e: any) => onChange(e, field, index)}/>
                    </Col>
                  )
                })}
                <Col sm={12} lg={1} className="text-right">
                  <Label>&nbsp;</Label><br/>
                  <button className="btn btn-primary btn-sm mt-2" type="submit">
                    <FontAwesomeIcon icon={faPlus}/> {t("add")}
                  </button>
                </Col>
              </Row>
              <div
                style={{minHeight: 160, maxHeight: 320}}
                className="bg-light p-2 my-3 overflow-y-auto">
                {!!rules.length ? (
                  <Row className="align-items-center">
                    {rules.map((r, index) => {
                      return (
                        <Fragment key={index}>
                          <Col sm={12} md={3} className="my-1">
                            <Card>
                              <CardHeader className="d-flex justify-content-between pe-2">
                                <small>{capitalize(r.label ?? r.field)}</small>
                                <FontAwesomeIcon
                                  onClick={() => setRules(onDeleteRule(rules, index))}
                                  className="pointer mr-2"
                                  icon={faTimes}/>
                              </CardHeader>
                              <CardBody>
                                <span>{t(conditions.find(c => c.value === r.condition)?.label ?? "")}</span>{" "}
                                {r.condition !== ConditionType.NO_EXIST && r.condition !== ConditionType.EXIST &&
                                    <b>{capitalize(r.value?.toString() || t("empty"))}</b>}{" "}
                              </CardBody>
                            </Card>
                          </Col>
                          {index !== rules.length - 1 && (
                            <Col sm={12} lg={1}>
                              <Switch
                                onChange={e => setRules(onChangeValues(rules, index, {
                                  ...r,
                                  operator: e ? Operator.AND : Operator.OR
                                }))}
                                checkedChildren={t("AND")}
                                unCheckedChildren={t("OR")}
                                defaultChecked={r.operator === Operator.AND}
                              />
                            </Col>
                          )}
                        </Fragment>
                      )
                    })}
                  </Row>
                ) : (
                  <div style={{height: 160}} className="d-flex justify-content-center align-items-center">
                    Empty rule
                  </div>
                )}
              </div>
            </form>
            <div className="d-flex justify-content-end">
              <button
                onClick={() => onSuccess ? onSuccess(rules) : null}
                className="btn btn-primary btn-sm"
                type="submit">
                <FontAwesomeIcon className="me-1" icon={faCheck}/>{t("save")}
              </button>
            </div>
          </div>
        ) : (
          <div className="text-center">
            {!existC && <p>¡{t("warn_rules_creator_condition")}!</p>}
            {!existCm && <p>¡{t("warn_rules_creator_comparator")}!</p>}
            {!existF && <p>¡{t("warn_rules_creator_comparator")}!</p>}
          </div>
        )}
      </CardBody>
    </Card>
  )
  
}


export default RuleGenerator;

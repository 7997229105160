import * as React from 'react';
import {CSSProperties, useCallback, useState} from 'react';
import {Column} from "../CustomTableComponent";
import {DropdownItem,} from 'reactstrap';
import useTranslate from "../../../hooks/useTranslate";
import ButtonComponent from "../ButtonComponent";
import {
    faCirclePlus,
    faSearch,
    faSortAlphaDown,
    faSortAlphaDownAlt,
    faSortDown,
    faTrash
} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {containsNumbers, evalExpression, sortIntFun, sortStringFun} from "../../../utils";
import styled from "styled-components";
import {Controller, useFieldArray, useForm} from "react-hook-form";
import SelectComponent from "../FieldComponents/SelectComponent";
import {Popover, Switch} from "antd";
import {SbxConditionType, SbxModelField} from "../../../types/Sbx";


type Props = {
    column: Column
    columnItems: any[]
    filterRowsByColumn: ({valueList, search}: { valueList?: (string | number)[], search?: string }) => void
    sortColumn: ({asc}: { asc?: boolean }) => void
};

const QueryContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 0.1fr;
  align-items: center;
  gap: 10px;
`

type ConditionType = { condition: string, value: string | number, operator?: "|" | "&" }

const conditionOptions = (t: (str: string) => string) => [
    {
        label: t("EQUAL_TO"),
        value: SbxConditionType.EQUAL_TO
    },
    {
        label: t("GREATER_THAN"),
        value: SbxConditionType.GREATER_THAN,
        type: SbxModelField.INT
    },
    {
        label: t("SMALLER_THAN"),
        value: SbxConditionType.SMALLER_THAN,
        type: SbxModelField.INT
    },
    {
        label: t("DIFFERENT_OF"),
        value: "!==",
        type: SbxModelField.INT
    },
    {
        label: t("START_LIKE"),
        value: "'{column}'.startsWith('{value}')",
        type: SbxModelField.STRING
    }, {
        label: t("END_LIKE"),
        value: "'{column}'.endsWith('{value}')",
        type: SbxModelField.STRING
    },
    {
        label: t("CONTAIN_LIKE"),
        value: "'{column}'.includes('{value}')",
        type: SbxModelField.STRING
    }
];

const TableColumnFilterMenuComponent = ({column, sortColumn, columnItems, filterRowsByColumn}: Props) => {

    const {
        control,
        register, watch
    } = useForm<{ conditions: ConditionType[] }>();
    const {fields, append, replace, remove} = useFieldArray({
        control,
        name: "conditions"
    });

    const [checkedItems, setCheckedItems] = useState<{ [item: string]: boolean }>({});
    const [search, setSearch] = useState("")
    const [isCheckedApply, setIsCheckedApply] = useState(true);

    const toggleAutoApply = React.useCallback(
        () => setIsCheckedApply(state => !state),
        [setIsCheckedApply],
    );

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const item = event.target.name;
        const isChecked = event.target.checked;
        let nCheckedItems: { [item: string]: boolean } = {}
        if (item === t("select_all")) {
            nCheckedItems = Object.keys(checkedItems).reduce((acc: { [item: string]: boolean }, key) => {
                acc[key] = isChecked
                return acc
            }, {})
        } else {
            nCheckedItems = {...checkedItems, [item]: isChecked}
        }

        setCheckedItems(nCheckedItems)
        if (isCheckedApply) {
            const checkedList = Object.keys(nCheckedItems).filter((key) => nCheckedItems[key] && key !== t("select_all"))
            filterRowsByColumn({valueList: checkedList})
        }

    };

    const setDefaultValues = useCallback(() => {
        const checkedMap: { [item: string]: boolean } = {
            [t("select_all")]: true
        }
        for (const item of columnItems) {
            checkedMap[item] = true
        }

        setCheckedItems(checkedMap)
        setSearch("")
    }, [columnItems]);

    React.useEffect(() => {
        if (columnItems.length > 0) {
            if (Object.keys(checkedItems).length === 0) {
                setDefaultValues()
            } else {
                if (columnItems.some(item => !checkedItems.hasOwnProperty(item))) {
                    let nCheckedItems = {...checkedItems}
                    const newItems = columnItems.filter(item => !checkedItems.hasOwnProperty(item))
                    for (const item of newItems) {
                        nCheckedItems[item] = true
                    }
                    setCheckedItems(nCheckedItems)
                }
            }
        }
    }, [columnItems, checkedItems]);


    const {t} = useTranslate('common');

    React.useEffect(() => {
        if (Object.keys(checkedItems).length > 0) {
            const subscription = watch((value, {name}) => {

                if (name?.includes(".") || name === "conditions") {
                    const [fieldName] = name.split(".")
                    if (fieldName === "conditions" && value["conditions"] && value["conditions"].some(condition => condition?.value)) {
                        const condition = value["conditions"].reduce((str: string, condition) => {
                            if (condition?.value) {
                                if (condition.condition?.includes("{value}")) {
                                    const replacedCondition = condition.condition.replaceAll("{value}", condition.value.toString().toLowerCase())
                                    str += ` ${value["conditions"]!.length > 1 ? condition.operator ?? "" : ""} ${replacedCondition}`
                                } else {
                                    str = str + ` ${value["conditions"]!.length > 1 ? condition.operator ?? "" : ""} {column} ${condition.condition} ${condition.value}`
                                }
                            }
                            return str
                        }, "")

                        // console.log("condition", condition)

                        const checkedList = Object.keys(checkedItems).filter((key) => {
                            return evalExpression(condition.replaceAll("{column}", key.toLowerCase())) && key !== t("select_all")
                        })
                        filterRowsByColumn({valueList: checkedList})
                    } else {
                        if (name === "conditions" && value["conditions"] && value["conditions"].length === 0) {
                            filterRowsByColumn({})
                        }
                    }
                }

            });
            return () => subscription.unsubscribe();
        }

    }, [watch, checkedItems]);

    const content = () => {
        return <div style={{width: "25em", minHeight: '50vh', maxHeight: "80vh"}} className="d-flex flex-column p-2 ">

            <div className="d-flex flex-column">
                <span className="text-capitalize">{t("report:sort")}</span>
                <div className="d-flex align-items-center gap-2">
                    <ButtonComponent outline label={t("ascending")} onClick={() => {
                        sortColumn({asc: true})
                    }} icon={faSortAlphaDown}/>
                    <ButtonComponent outline label={t("descending")} onClick={() => {
                        sortColumn({asc: false})
                    }} icon={faSortAlphaDownAlt}/>
                </div>
            </div>

            <DropdownItem divider/>

            <div className="d-flex flex-column  mb-3">
                <div className="d-flex justify-content-end mb-2">
                    <ButtonComponent label={t("add_condition")} size={"sm"} onClick={() => {
                        let conditions: ConditionType = {condition: "", value: ""}
                        if (fields.length > 0) {
                            conditions.operator = "&"
                        }
                        append(conditions)
                    }} icon={faCirclePlus}/>
                </div>

                {fields.length > 0 && <div className={`overflow-auto  border-bottom`} style={{maxHeight: "11em"}}>
                    {fields.map((field, index) => (
                        <div className="d-flex flex-column gap-2" key={field.id + " " + index}>
                            {index > 0 && <div>
                                <Controller
                                    render={({field}) => <Switch
                                        checked={field.value === "&"}
                                        unCheckedChildren={t('OR')}
                                        checkedChildren={t('AND')}
                                        onChange={checked => field.onChange(checked ? "&&" : "||")}/>}
                                    name={`conditions.${index}.operator`}
                                    control={control}
                                />
                            </div>}
                            <QueryContainer className="mb-1">

                                <Controller
                                    render={({field}) => <SelectComponent id={""} menuPosition={"fixed"}
                                                                          value={conditionOptions(t).find(option => option.value === field.value)}
                                                                          className="" styles={{
                                        control: (baseStyles: CSSProperties) => ({
                                            ...baseStyles,
                                            height: "10px",
                                        }),
                                    }} name={""}
                                                                          options={conditionOptions(t).filter(option => !option.type || (option.type === (containsNumbers(columnItems.slice(0, 10).filter(item => item)[0]) ? SbxModelField.INT : SbxModelField.STRING)))}
                                                                          onChange={evt => field.onChange(evt.value)}/>}
                                    name={`conditions.${index}.condition`}
                                    control={control}
                                />

                                <input type="text"
                                       className="form-control flex-grow-1"
                                       placeholder={t("value")} {...register(`conditions.${index}.value`)}/>
                                <ButtonComponent label={""} size={"sm"} onClick={() => remove(index)}
                                                 sizeIcon={"1x"}
                                                 icon={faTrash} color={"danger"}/>
                            </QueryContainer>


                        </div>
                    ))}
                </div>}
            </div>

            <div className="d-flex flex-column mb-2">

                <div className="d-flex align-items-center border rounded mb-2">
                    <input type="text" className="form-control border-0" value={search}
                           onChange={event => {
                               setSearch(event.target.value)
                               if (isCheckedApply) {
                                   filterRowsByColumn({search: event.target.value})
                               }
                           }} placeholder={`${t("inputSearch")}...`}/>
                    <div className="p-2">
                        <FontAwesomeIcon icon={faSearch}/>
                    </div>
                </div>


                <div style={{maxHeight: "250px"}} className="overflow-auto ">
                    <ul className="list-group">

                        <li key={t("select_all")} className="list-group-item d-flex align-items-center gap-2">
                            <input
                                type="checkbox"
                                name={t("select_all")}
                                checked={checkedItems[t("select_all")] ?? false}
                                onChange={handleChange}
                            />
                            <span>{t("select_all")}</span>
                        </li>

                        {columnItems.length > 0 && columnItems.sort((a, b) => typeof a === "string" ? sortStringFun({
                            a,
                            b,
                            type: "ASC"
                        }) : sortIntFun({
                            a,
                            b,
                            type: "ASC"
                            // })).filter(name => search.length > 0 ? (search.toLowerCase() ?? "").includes((name.toLowerCase() ?? "") ?? "") : true).map((name) => (
                        })).filter(name => search.length > 0 ? ((name ?? "").toString().toLowerCase() ?? "").includes((search.toLowerCase() ?? "") ?? "") : true).map((name, index) => (
                            <li key={`${name}_${index}`} className="list-group-item d-flex align-items-center gap-2">
                                <input
                                    type="checkbox"
                                    name={name}
                                    checked={checkedItems[name] ?? false}
                                    onChange={handleChange}
                                />
                                <span>{name}</span>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="d-flex align-items-center gap-2 ">
                <input
                    type="checkbox"
                    name={t("apply_auto")}
                    checked={isCheckedApply}
                    onChange={toggleAutoApply}
                />
                <span>{t("apply_auto")}</span>
            </div>


            <div className="d-flex align-items-center justify-content-end gap-2 border-top pt-2">
                <ButtonComponent outline label={t("apply_filter")} disabled={isCheckedApply} size={"sm"}
                                 color={"primary"}
                                 onClick={() => {
                                     filterRowsByColumn({
                                         search,
                                         valueList: Object.keys(checkedItems).filter((key) => checkedItems[key] && key !== t("select_all"))
                                     })
                                 }}/>

                <ButtonComponent outline label={t("clear_filter")} size={"sm"} color={"danger"} onClick={() => {
                    setDefaultValues()
                    replace([])
                    filterRowsByColumn({})
                }}/>
            </div>


        </div>
    }


    return <Popover placement="bottom" overlayStyle={{zIndex: 3333}} content={content()} trigger="click">
        <FontAwesomeIcon icon={faSortDown} className="pointer mx-2"/>
    </Popover>

};

export default TableColumnFilterMenuComponent
import {Condition} from "../../types/Sbx";
import {getSbxModelFields} from "./SbxService";

const row_model = "sales_addressee";

export function getSalesAddresseeByAccountService(account_key: string) {
  const where: Condition[] = [{
    'ANDOR': 'AND',
    'GROUP': [
      {'ANDOR': 'AND', 'VAL': account_key, 'FIELD': 'account', 'OP': '='}]
  }
  ];
  return getSbxModelFields({
    provider: {
      query: JSON.stringify({
        where,
        row_model,
        fetch: ['city', 'country', 'sales_org', 'sales_channel', 'state']
      })
    }
  }).then(res => {
    if (res.success && res.results) {
      res.items = res.results.map(sale => {
        return {
          ...sale,
          sales_org_info: `${sale.sales_org.org_code} - ${sale.sales_org.org_name}`,
          sales_channel_info: `${sale.sales_channel.code} - ${sale.sales_channel.name}`,
          company_destinatario_info: `${sale.code} - ${sale.name}`,
        };
      });
    }
    return res;
  });
}

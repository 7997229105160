import * as React from 'react';
import {CSSProperties, useState} from 'react';
import {StringOption} from "../../../types/Select";
import useTranslate from "../../../hooks/useTranslate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimesCircle} from "@fortawesome/free-solid-svg-icons";

type Props = {
    setValue: (value: string) => void
    value: string
    options: StringOption[]
    id: number
    style?: CSSProperties
    setFocusField?: (field: string) => void
};

const SuggestionRuleSearchComponent = ({setValue, value, options, id, setFocusField, style}: Props) => {
    const [suggestSearch, setSuggestSearch] = useState("");
    const {t} = useTranslate("common");

    const setSuggestionValue = (suggestionValue: string, id: number) => {
        if (id) {
            const element: any = document.getElementById(id.toString())
            let defaultValue = (value ?? "")


            if (element) {
                const [start, end] = [element.selectionStart, element.selectionEnd];
                const endStr = defaultValue.lastIndexOf(getValue())
                defaultValue = defaultValue.slice(0, endStr).trim()
                setValue(defaultValue.slice(0, start ?? 1) + ' ' + (suggestionValue ?? '') + defaultValue.slice(end ?? 1).trim())
            }
        }
    }

    const getValue = () => {
        return value.toLowerCase().replace(/[\])}[{(]/g, '').split(" ").at(-1) ?? ""
    }

    return (
        <div className="position-absolute bg-white shadow border p-2 z-index-1000" style={style ?? {}} >
            <div className="input-group my-2">
                <input type="text" value={suggestSearch} className="border-0 border-bottom border-end-0 border-start-0 form-control" onChange={event => {
                    setSuggestSearch((event.currentTarget.value as string) ?? "")
                }} placeholder={"search"}/>
                <span className="bg-white border-0 border-bottom input-group-text pointer" onClick={() => setSuggestSearch("")} id="basic-addon2">
              {suggestSearch && <FontAwesomeIcon icon={faTimesCircle}/>}
            </span>
            </div>
            <div className="d-flex flex-column  overflow-auto"
                 style={{maxHeight: '200px', maxWidth: '400px', minWidth: '300px'}}>

                <ul className="list-group list-group-flush">
                    {options.filter(pField =>
                        suggestSearch ? pField.value.toLowerCase().includes(suggestSearch.toLowerCase()) :
                            ((pField.value as string).toLowerCase().includes(getValue())))
                        .map(dataField => (
                            <li key={dataField.value} onClick={() => {
                                setSuggestionValue(dataField.value, id)
                                if (setFocusField) {
                                    setFocusField("")
                                }
                            }}
                                className="list-group-item pointer shading">{dataField.value}</li>
                        ))}
                </ul>


            </div>

            <div className=" text-end">
            <small className="fw-bold pointer text-link mx-2" style={{textDecoration: "underline"}}
                   onClick={() => setFocusField && setFocusField("")}>{t("close")}</small>
            </div>
        </div>
    );
};

export default SuggestionRuleSearchComponent
import {useEffect, useState} from "react";
import {State} from "../types/State";
import {useRouter} from "next/router";
import {actionHistory} from '../store/SearchReducer';
import {useDispatch, useSelector} from 'react-redux';
import {searchReducer} from "../store/Selectors";

const useRouterLoadingEvents = () => {

  const [loadingPage, setLoadingPage] = useState(State.IDLE)
  const {text} = useSelector(searchReducer)

  const router = useRouter()
  const dispatch = useDispatch();
  useEffect(() => {
    const handleRouteChangeStart = (url: string, {shallow}: { shallow: boolean }) => {
      setLoadingPage(State.PENDING)
    }
    const handleRouteChangeComplete = (url: string, {shallow}: { shallow: boolean }) => {
      setLoadingPage(State.RESOLVED)
      if (text.length > 0){
        dispatch(actionHistory.changeText(""))
      }
    }
    const handleRouteChangeError = (err: { cancelled: any; }, url: string) => {
      if (err?.cancelled) {
        console.log(`Route to ${url} was cancelled!`)
      }
      setLoadingPage(State.REJECTED)
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)
    router.events.on('routeChangeError', handleRouteChangeError)

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
      router.events.off('routeChangeError', handleRouteChangeError)
    }
  }, [])

  return [loadingPage]
}

export default useRouterLoadingEvents;

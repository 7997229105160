import {
  appointmentPickerField,
  checkboxField,
  currencyField,
  DateField,
  DateRangeField,
  documentField,
  emailField,
  fieldsContainer,
  largeTextField,
  MultiFileField,
  multiSelectField,
  numberField,
  passwordField,
  phoneField,
  radioField,
  selectField,
  selectToDoField,
  SingleFileField,
  smallTextField,
  tableField,
  timeField,
  titleField
} from './Fields';
import {uuidV4} from "../../../utils";
import IField from "../../../types/FormBuilder/IField";


const commonInputs: Array<{ field: IField; key: string }> = [
  {key: uuidV4(), field: checkboxField},
  {key: uuidV4(), field: radioField},
  {key: uuidV4(), field: smallTextField},
  {key: uuidV4(), field: currencyField},
  {key: uuidV4(), field: phoneField},
  {key: uuidV4(), field: timeField},
  {key: uuidV4(), field: emailField},
  {key: uuidV4(), field: documentField},
  {key: uuidV4(), field: DateField},
  {key: uuidV4(), field: DateRangeField},
  {key: uuidV4(), field: numberField},
  {key: uuidV4(), field: passwordField},
  {key: uuidV4(), field: largeTextField},
  {key: uuidV4(), field: selectField},
  {key: uuidV4(), field: multiSelectField},
  {key: uuidV4(), field: SingleFileField},
  {key: uuidV4(), field: MultiFileField},
  {key: uuidV4(), field: tableField},
  {key: uuidV4(), field: fieldsContainer},
  {key: uuidV4(), field: titleField},
  {key: uuidV4(), field: selectToDoField},
  {key: uuidV4(), field: appointmentPickerField},
];

commonInputs.forEach(item => {
  item.field.key = item.key;
});

export const getFieldByKey = (key: string): IField => {
  const element = INPUT_BUILDER_TYPES.map(group =>
    group.inputs.map(input => input.field),
  )
    .flat()
    .find(input => input.key === key);
  return element ? element : smallTextField;
};

export function getFieldByType(type: string, singleValue = true): IField {
  const element = INPUT_BUILDER_TYPES.map(group =>
    group.inputs.map(input => input.field),
  )
    .flat()
    .find(field => {
      const typeCondition = field.sub_type
        ? field.sub_type === type
        : field.field_type === type;
      return typeCondition && field.single_value === singleValue;
    });
  return element ? element : smallTextField;
}

export function getInputByType(field: IField): JSX.Element | undefined {
  return getFieldByType(getIdByType(field), field.single_value).input;
}

export function getInputByField(field: IField): JSX.Element | undefined {
  return getFieldByType(getIdByType(field), field.single_value).input;
}

export function getInputComponentByField(field: IField) {
  return getFieldByType(getIdByType(field), field.single_value);
}


export function getInputByKey(key: string): JSX.Element | undefined {
  return getFieldByKey(key).input;
}

export function getIdByType(field: IField): string {
  return field.sub_type && field.sub_type !== "LIST" ? field.sub_type : field.field_type;
}

export function getLabel(field: IField): string {
  return field.label?.split(' - ')[0] || 'default';
}

export function duplicateField(field: IField): IField {
  const element = Object.assign({}, field);
  delete element.icon;
  delete element.input;
  delete element.component;
  delete element.key;
  return element;
}

export const INPUT_BUILDER_TYPES = [
  {
    key: uuidV4(),
    name: 'Comunes',
    inputs: commonInputs,
  },
];

import {Appointment} from "../../types/Appointment";
import {post} from "../../network";
import {deleteSbxModelService, findByModel} from "./SbxService";
import {Andor, Condition, SbxConditionType} from "../../types/Sbx";
import {organizationFindQuery} from "../../utils";

const row_model = "crm_appointment";

function getAppointment(appointment: Appointment): Appointment {
  let apo: any = Object.assign({}, appointment);
  if (apo.account_data) apo.account = apo.account_data.data._KEY;
  delete apo.account_data;
  delete apo._META;
  return apo;
}

export function saveAppointmentService(appointment: Appointment) {
  return post("/api/data/v1/row/model/insert", {row_model, rows: [getAppointment(appointment)]});
}

export function updateAppointmentService(appointment: Appointment) {
  return post("/api/data/v1/row/model/update", {row_model, rows: [getAppointment(appointment)]});
}


export async function getAppointmentByKeysAndKey(type: "crm_user" | "account", keys: (string | number)[], key?: string) {

  let where: Condition[] | any = null;
  if (keys.length) {
    where = [{ANDOR: Andor.AND, GROUP: []}];
    keys.forEach(keyValue => where[0].GROUP.push({
      OP: SbxConditionType.EQUAL_TO,
      VAL: keyValue,
      ANDOR: Andor.OR,
      FIELD: type
    }, {
      OP: SbxConditionType.LIKE,
      VAL: `%${keyValue}%`,
      ANDOR: Andor.OR,
      FIELD: "invitees"
    }));
    if (key) {
      where[0].GROUP.push({
        OP: SbxConditionType.EQUAL_TO,
        VAL: key,
        ANDOR: Andor.OR,
        FIELD: "_KEY"
      })
    }
  } else {
    return {success: true, items: []};
  }

  return post("/api/data/v1/row/model/find", {
    page: 1,
    size: 1000,
    row_model,
    where,
    fetch: ["account", "account.company"]
  }).then(res => {
    return {
      ...res,
      items: res.results.map((data: Appointment) => {
        let account = res.fetched_results.sbx_crm[data.account];
        if (account) {
          account.company = res.fetched_results.company[account.company];
        }
        return {
          ...data,
          account_data: account ? {
            value: account.account_id,
            label: account.account_id + " - " + account.company.company_name,
            data: account
          } : null
        }
      })
    }
  })
}

export async function getAllAppointmentsService(account_key?: string) {
  let where: Condition[] = [];

  if (account_key) {
    where = [{
      'ANDOR': 'AND',
      'GROUP': [
        {
          'ANDOR': 'AND',
          'VAL': account_key,
          'FIELD': 'account',
          'OP': '='
        }]
    }]
  }

  if (where) {
    where = await organizationFindQuery({where, row_model})
  }

  return post("/api/data/v1/row/model/find", {
    page: 1,
    size: 1000,
    row_model,
    where,
    fetch: ["account", "account.company"]
  }).then(res => {
    return {
      ...res,
      items: res.results.map((data: Appointment) => {
        let account = res.fetched_results.sbx_crm[data.account];
        if (account) {
          account.company = res.fetched_results.company[account.company];
        }
        return {
          ...data,
          account_data: account ? {
            value: account.account_id,
            label: account.account_id + " - " + account.company.company_name,
            data: account
          } : null
        }
      })
    }
  })
}

export function getUserByIdFromSBX(id: number) {
  return findByModel({
    row_model: "sbx_crm_user", where: [{
      ANDOR: Andor.AND,
      GROUP: [
        {
          ANDOR: Andor.AND,
          OP: "=",
          VAL: id,
          FIELD: "user_id"
        }
      ]
    }]
  }).then(res => {

    return {
      ...res,
      item: res.results[0]
    }
  })
}

export function deleteAppointmentService(appointmentKey: string) {
  return deleteSbxModelService({row_model, keys: [appointmentKey]})
}

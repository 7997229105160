import React, {useState} from 'react';
import {TableFormColumn} from '../../../TaskComponent/TableForm/TableTaskComponent';
import {TableLoadingState, TableState} from './TableEditModal';
import {Col, FormGroup} from 'reactstrap';
import {Model, SbxGeneralRow, SbxModelField, SbxQuery, SbxResponse} from '../../../../types/Sbx';
import {State} from '../../../../types/State';
import {Control, Controller, UseFormGetValues, UseFormRegister, UseFormSetValue, UseFormWatch} from 'react-hook-form';
import useTranslate from '../../../../hooks/useTranslate';
import {Switch} from 'antd';
import {
  capitalize,
  convertDateToNumberDate,
  convertDateToYYYYMMDD,
  convertNumberDateToDate,
  debounceTime,
  evalExpression,
  getArrayValueInDeep,
  getDefaultVarsFromStr,
  getDynamicListProviderQuery,
  getNextBusinessDay,
  getObjValueInDeep,
  getProviderOptions,
  getReferenceSelectOptions,
  getVariableDefaultValue,
  isDefaultVarExpression,
  isVarExpression, ProviderOptionsResponse,
  removeDuplicateFromArrayObj, uuidV4
} from '../../../../utils';
import SelectComponent from '../../FieldComponents/SelectComponent';
import DateComponent from '../../FieldComponents/DateComponent';
import {authReducer, configReducer} from '../../../../store/Selectors';
import {useSelector} from 'react-redux';
import {getProviderById} from '../../../../services/backend/FormService';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Find} from 'sbxcorejs';
import {getSbxModelFields} from '../../../../services/backend/SbxService';
import {SubType} from '../../../../types/FieldType';
import CurrencyComponent from '../../FieldComponents/CurrencyComponent';
import {ProviderType} from "../../../../types/ProviderType";
import {ColumnsLabels, SelectSubType} from "../../../../types/Field";


type Props = {
  header: TableFormColumn;
  index: number;
  getSearchableData: (header: TableFormColumn, search: string) => void
  tableState: TableState;
  item?: { [key: string]: any }
  dispatchForm: ({name, value}: { name: string, value: any }) => void;
  dispatchReferenceFields: ({name, value}: { name: string, value: any }) => void;
  // dispatchMultiForm: (forms: { name: keyof TableState; value: any; }[]) => void
  register: UseFormRegister<any>;
  setValue: UseFormSetValue<any>
  watch: UseFormWatch<any>;
  inputType: SbxModelField | 'list_provider' | 'time'
  isGroupInput?: boolean
  control: Control<any, object>
  getValue: UseFormGetValues<any>
  getFormValues?: UseFormGetValues<any>,
  taskFormState?: { [key: string]: any }; //Form values in process execution
  cacheTableData?: { [key: string]: string | number }
  // dispatchReferenceField: ({name, value}: { name: string, value: any }) => void;
};


// This obj is to save keys from the form that have an autocomplete var. So, each time var change, all the form is refreshed.
const cacheRefresh: { [key: string]: string[] } = {};

const TableInputComponent = ({
                               header,
                               index,
                               tableState,
                               item,
                               dispatchForm,
                               register,
                               inputType,
                               watch,
                               isGroupInput,
                               setValue,
                               control,
                               dispatchReferenceFields, getSearchableData,
                               getValue, getFormValues, taskFormState, cacheTableData
                             }: Props) => {

  const {t} = useTranslate('common');
  const {user} = useSelector(authReducer);
  const {businessDays} = useSelector(configReducer);
  const headerName = header?.type === 'constant' ? header.constant : header.name;
  const [selectValue, setSelectValue] = useState<{ label: string, value: string } | null>(null);
  const [selectOptions, setSelectOptions] = useState<{ label: string, value: string }[]>([]);
  const [defaultValue, setDefaultValue] = useState('');
  const [loading, setLoading] = useState<State>(State.IDLE);
  const watchAllFields = watch(); // when pass nothing as argument, you are watching everything
  const getHeaderInputName = () => {
    return header.parent ? `${header.parent}.${header.name}` : headerName ?? header.name;
  };

  React.useEffect(() => {

    if (tableState.selectLoading.includes(TableLoadingState.PENDING_PROVIDER) || tableState.selectLoading.includes(TableLoadingState.PENDING_MODELS)) {
      setLoading(State.PENDING);
    } else {
      if (tableState.selectLoading.includes(TableLoadingState.RESOLVED_PROVIDER) || tableState.selectLoading.includes(TableLoadingState.RESOLVED_MODELS)) {
        setLoading(State.RESOLVED);
      }
    }

  }, [tableState.selectLoading]);

  React.useEffect(() => {
    const getInputState = async () => {
      if (tableState) {

        const header_reference = header.parent ? header.name : getHeaderInputName();

        const reference_field = (tableState.model as Model[]).find(model => model.name === header_reference);

        if (inputType === 'list_provider' && !selectValue) {
          // Set options for list provider type
          setLoading(State.PENDING);
          const reference = header.reference ?? (header.value ? header.value[0] : "")
          if (header.render_type === 'field') {
            if (header.format_rules?.default_value && reference && tableState?.reference_fields[reference]) {
              let items = tableState?.reference_fields[reference];
              if (header.format_rules.condition_order_by) {
                const order_by = header.format_rules.condition_order_by[0];
                items = items.sort((a: any, b: any) => (getObjValueInDeep(a, order_by) > getObjValueInDeep(b, order_by)) ? -1 : 1);
              }
              const default_var = getVariableDefaultValue(header.format_rules.default_value);
              setLoading(State.RESOLVED);
              setValue(getHeaderInputName(), getObjValueInDeep(items[0], default_var));
            } else {
              setLoading(State.RESOLVED);
            }
          } else {
            let options = [];

            if (header.column && tableState?.reference_fields[header.column]) {
              // options = tableState?.reference_fields[header.column].map((item: { label: string, value: string }) => ({
              //   label: item.label,
              //   value: item.value
              // }));

              options = getReferenceSelectOptions({
                header,
                options: tableState?.reference_fields[header.column],
                isProvider: true
              });


              if (options.length === 0  && tableState?.reference_fields[header.column].length > 0 && tableState?.reference_fields[header.column].some((item: any) => item.provider_type === ProviderType.DATABASE)){
                options = tableState?.reference_fields[header.column].map((item: { label: string, value: string }) => ({
                  label: capitalize(item.label) ?? "",
                  value: item.value ?? ""
                }));
              }

            } else {
              // const column = header.format_rules && header.format_rules.columns_labels ? header.format_rules.columns_labels[0].name : '';

              if (header.list_provider_type === ProviderType.DATABASE) {
                options = tableState?.reference_fields[reference];
              } else {
                options = getReferenceSelectOptions({
                  header,
                  options: tableState?.reference_fields[reference],
                  isProvider: true
                });
              }


              // options = tableState?.reference_fields[header.value ? header.value[0] : '']?.map((fieldColumn: { [x: string]: any; }) => ({
              //   label: fieldColumn[column] ?? '',
              //   value: fieldColumn['_KEY']
              // })).filter((item: { label: string }) => item.label) ?? [];
            }

            let itemKey: { label: string, value: string } | string = '';

            if (item) {
              if (item[header.name]?.value) {
                itemKey = item[header.name].value;
              } else if (item[getHeaderInputName()]?.hasOwnProperty('_KEY')) {
                itemKey = item[getHeaderInputName()]['_KEY'].hasOwnProperty('value') ? item[getHeaderInputName()]['_KEY'].value : item[getHeaderInputName()]['_KEY'];
              } else {
                if (item[header.name]) {
                  itemKey = item[header.name];
                }
              }
            }

            if (item && item[header.name] && options?.find((option: { value: string, label: string }) => (option.value === itemKey))) {

              const option = options.find((option: { value: any; }) => (option.value === itemKey));
              const defaultValue = {
                label: option?.label ?? '',
                value: option?.value ?? ''
              };


              if (defaultValue) {
                setValue(getHeaderInputName(), defaultValue.value);
                setSelectValue(defaultValue);
              }
            } else {
              if (tableState.form[getHeaderInputName()]) {
                const valueKey: string = tableState.form[getHeaderInputName()] as string;
                const optionValue = options.find((option: { value: any; }) => option.value === valueKey);
                const defaultValue = {
                  label: optionValue?.label ?? '',
                  value: optionValue?.value ?? optionValue
                };



                if (defaultValue.value) {
                  setValue(getHeaderInputName(), defaultValue.value);
                  setSelectValue(defaultValue);
                }
              }
            }


            options = removeDuplicateFromArrayObj(options, 'value');


            setSelectOptions(header.format_rules?.condition_order_by ? options : options.sort((a: { label: string, value: string }, b: { label: string, value: string }) => a.label.localeCompare(b?.label ?? '')));
            setLoading(State.RESOLVED);
          }


        } else if (inputType === SbxModelField.REFERENCE && tableState) {

          let defaultValue = null;


          if (Object.keys(tableState.reference_fields).length > 0 && tableState.reference_fields[reference_field?.reference_type_name ?? header_reference]) {
            setLoading(State.PENDING);
            let reference_options = tableState?.reference_fields[reference_field?.reference_type_name ?? header_reference];

            if (header.filter) {
              if (header?.filter?.key_column_filter && header.filter?.main_column_filter && reference_options && reference_options.length > 0 && tableState.form[header.filter.key_column_filter]) {
                setSelectValue(null);
                reference_options = reference_options.filter((option: any) => option[header.filter?.main_column_filter ?? ''] === tableState.form[header.filter?.key_column_filter ?? '']);
              }
            }

            let options = getReferenceSelectOptions({
              header,
              options: reference_options
            });

            if (options?.length > 0) {
              let itemKey: { label: string, value: string } | string = '';

              if (item) {
                // Check if value exist in our form, if exist we'll use it else we'll use the value by default in item
                if (tableState.form[getHeaderInputName()] && !isDefaultVarExpression(tableState.form[getHeaderInputName()] as string)) {
                  itemKey = tableState.form[getHeaderInputName()] as string;
                } else {
                  if (item[getHeaderInputName()]?.hasOwnProperty('_KEY')) {
                    itemKey = item[getHeaderInputName()]['_KEY'].hasOwnProperty('value') ? item[getHeaderInputName()]['_KEY'].value : item[getHeaderInputName()]['_KEY'];
                  }
                }
              }


              if (item && itemKey && options?.find((option: { value: string, label: string }) => option.value === itemKey)) {
                const optionValue = options.find((option: { value: any; }) => option.value === itemKey);

                defaultValue = {
                  label: optionValue?.label ?? getSelectLabel(item[getHeaderInputName()]),
                  value: optionValue?.value ?? optionValue
                };
                if (defaultValue.value) {
                  setValue(getHeaderInputName(), defaultValue.value);
                  setSelectValue(defaultValue);
                }
              } else {
                if (tableState.form[getHeaderInputName()]) {
                  let valueKey: string = tableState.form[getHeaderInputName()] as string;

                  if (isDefaultVarExpression(valueKey)){
                    const defaultValue1 = getVariableDefaultValue(valueKey)
                    if (tableState.form[defaultValue1]){
                      valueKey = tableState.form[defaultValue1] as string
                    }
                  }

                  const optionValue = options.find((option: { value: any; }) => option.value === valueKey);
                  defaultValue = {
                    label: optionValue?.label ?? '',
                    value: optionValue?.value ?? optionValue
                  };

                  if (defaultValue.value) {
                    setValue(getHeaderInputName(), defaultValue.value);
                    setSelectValue(defaultValue);
                  }
                }
              }
              options = removeDuplicateFromArrayObj(options, 'value');

              setSelectOptions(header.format_rules?.condition_order_by ? options : options.filter(option => !!option.label).sort((a: { label: string, value: string }, b: { label: string, value: string }) => (a?.label.toString()).localeCompare(b?.label.toString())));
            } else {
              setSelectOptions([]);
            }


            setTimeout(() => {
              setLoading(State.RESOLVED);
            }, 2000);
          } else {
          }
        } else {

        }
      }
    };

    getInputState();


  }, [inputType, header, tableState, item]);

  const getSelectLabel = (fieldColumn: { [key: string]: any }) => {
    const column = header.sub_columns ? header.sub_columns[0].name : '';
    return fieldColumn[column] ?? getObjValueInDeep(fieldColumn, column);
  };

  const updateFormByData = ({
                              model_in_deep,
                              value,
                              keyForm
                            }: { model_in_deep: string[], value: string, keyForm: string }) => {

    const model_base = model_in_deep[0] as string;
    const model_reference = (tableState.model as Model[]).find(model => model.name === model_base)?.reference_type_name;
    if (model_reference) {
      const reference_list = tableState.reference_fields[model_reference];
      if (reference_list?.length > 0) {
        const reference_item = reference_list.find((reference: SbxGeneralRow) => reference._KEY === value);
        if (reference_item) {
          const objValue = getArrayValueInDeep(reference_item, model_in_deep.slice(1));
          if (objValue) {
            dispatchForm({name: keyForm, value: objValue});
          } else {
            dispatchForm({name: keyForm, value: ''});
          }
        }
      }
    }
  };

  const getInputValue = ({value, model_base}: { model_base: string, value: string }) => {
    // If exist default values to autocomplete, this function allow take the default values according with the model name
    Object.keys(tableState.form).forEach(key => {
      const default_value = tableState.form[key];
      if (typeof default_value === 'string') {
        const cacheValue = cacheRefresh[key]?.find(deepVal => deepVal.includes(model_base));

        // Check if default value is a var value or it was saved before.
        if ((default_value || default_value === '') && ((isDefaultVarExpression(default_value) && default_value.includes(model_base)) || cacheValue)) {
          const defaultVar = cacheValue ?? getVariableDefaultValue(default_value as string);
          if (!cacheRefresh[key]) {
            cacheRefresh[key] = [];
          }

          if (!cacheRefresh[key].includes(defaultVar)) {
            cacheRefresh[key].push(defaultVar);
          }

          // Get var from default value and split "account.company.street" -> ["account", "company", "street"] and
          // search if base model ex: "account" has a value to autocomplete the default values that depends of account.
          const model_in_deep = defaultVar.split('.');
          updateFormByData({model_in_deep, value, keyForm: key});
        }
      }
    });
  };

  function setValueType(val: Date, type?: 'number') {
    switch (type) {
      case 'number':
        return convertDateToNumberDate(val);
      default:
        return val;
    }
  }

  const getDateValue = (type?: 'number') => {
    const header_item = header.parent ? header.name : getHeaderInputName();
    switch (type) {
      case 'number':
        if (getValue(getHeaderInputName())) {
          // setValue(header_item, new Date(getValue(header_item)));
          return convertNumberDateToDate(getValue(getHeaderInputName()));
        }
        if (item && item[header_item] && !tableState.form[header_item]) {
          // setValue(header_item, new Date(item[header_item]));
          return convertNumberDateToDate(item[header_item]);
        }
        return null;
      default:
        if (getValue(getHeaderInputName())) {
          // setValue(header_item, new Date(getValue(header_item)));
          return new Date(getValue(getHeaderInputName()));
        }
        if (item && item[header_item] && !tableState.form[header_item]) {
          // setValue(header_item, new Date(item[header_item]));
          return new Date(item[header_item]);
        }
        return null;
    }

  };

  const handleSelect = (evt: { label: any, value: any }) => {
    // This function update a select value -> {label: string, value: any}
    dispatchForm({name: getHeaderInputName(), value: evt?.value});
    setSelectValue(evt);
    getInputValue({model_base: getHeaderInputName(), value: evt.value});
  };

  React.useEffect(() => {
    if (item?.hasOwnProperty(getHeaderInputName()) && !header.default && item[getHeaderInputName()] && inputType !== SbxModelField.REFERENCE && inputType !== 'list_provider') {
      setDefaultValue(item[getHeaderInputName()]);
      setValue(getHeaderInputName(), item[getHeaderInputName()]);
    }

    if (item?.hasOwnProperty(getHeaderInputName()) && header.sub_type === SubType.CURRENCY) {
      setDefaultValue(item[getHeaderInputName()]);
      setValue(getHeaderInputName(), item[getHeaderInputName()]);
    }
  }, [item, header]);

  React.useEffect(() => {

    const getDefaultValue = () => {

      if (header.default || header.default === 0) {
        let value = null;

        // Check is default is a var expression like -> '${income_y1/kilos}'
        if (typeof header.default === 'string' && isDefaultVarExpression(header.default as string) && isVarExpression(header.default as string)) {
          let default_value = getVariableDefaultValue(header.default);
          // Split the default value to get the variables -> ["income_y1", "kilos"]
          // let new_value = default_value;

          const varList = default_value.split(/[\/,*+-]+/);
          varList.forEach(variable => {
            variable = variable.trim();

            // Get value for each variable of the expression.
            if ((watchAllFields[variable] || watchAllFields[variable] === 0) && default_value?.includes(variable)) {
              default_value = default_value.replace(variable, watchAllFields[variable]);
            }
          });


          value = evalExpression(default_value);
          if (header.calculated && value && cacheTableData) {
            cacheTableData[getHeaderInputName()] = value;
            // dispatchForm({name: getHeaderInputName(), value})
          }
        } else {
          switch (header.default) {
            case '${now}': {
              value = convertDateToYYYYMMDD(new Date());
              break;
            }
            case '${next_business_day}': {
              value = getNextBusinessDay(businessDays)
              break
            }

            case '${currentUser}':
              value = user?.email ?? '';
              break;
            default:
              if (item && item[getHeaderInputName()]) {
                value = item[getHeaderInputName()];
              } else {
                if (header.default === 0 || (header.default && !isDefaultVarExpression(header.default as string))) {
                  value = header.default;
                } else {
                  value = '';
                }
              }

              break;
          }
        }

        if (header.sub_type === SubType.CURRENCY && !value) {
        } else {
          setDefaultValue(value);
        }
      }
    };

    if (header.default || header.default === 0) {
      getDefaultValue();
    }
  }, [watchAllFields]);


  React.useEffect(() => {
    const getDependencyOption = async () => {
      for (const dependency of header.format_rules?.dependencies!) {
        if (header.list_provider && tableState.form) {
          setLoading(State.PENDING);
          const response = await getProviderById(parseInt(header.list_provider));
          if (response.success && response.item) {
            const varList = getDefaultVarsFromStr(response.item.query);

            if ((tableState.form[dependency] || getValue(dependency) || (getFormValues && getFormValues(dependency))) && header.list_provider && varList && varList.length > 0) {
              const formState = {...tableState.form, ...taskFormState};
              const listProvider = getDynamicListProviderQuery({
                list_provider: response.item,
                formState,
                getFormValues,
                getValue
              });


              if (listProvider?.query) {
                const responseOpt = await getProviderOptions({list_provider: listProvider}).then(res => res as SbxResponse);
                
                if (responseOpt?.items) {
                  let sbxItem = null;
                  const newConditions: string[] = [];
                  if (header.format_rules?.default_value_conditions && header.format_rules?.default_value_conditions!?.length > 0) {
                    const conditions = header.format_rules?.default_value_conditions;

                    let items = responseOpt.items;

                    if (header.format_rules.condition_order_by) {
                      const order_by = header.format_rules.condition_order_by[0];

                      items = items.sort((a, b) => (getObjValueInDeep(a, order_by) > getObjValueInDeep(b, order_by)) ? -1 : 1);
                    }

                    sbxItem = items.find(resItem => {


                      for (let condition of conditions) {

                        const defaultValueVars = getDefaultVarsFromStr(condition);
                        if (defaultValueVars && defaultValueVars.length > 0) {
                          for (const defaultVar of defaultValueVars) {
                            const defaultValue = getVariableDefaultValue(defaultVar);
                            if (getFormValues && getFormValues(defaultValue)) {
                              const value = getFormValues(defaultValue);
                              condition = condition?.replace(defaultVar, `"${value}"`);
                              continue;
                            }

                            if (getValue && getValue(defaultValue)) {
                              const value = getValue(defaultValue);
                              condition = condition?.replace(defaultVar, `"${value}"`);
                              continue;
                            }

                            if (formState[defaultValue]) {
                              condition = condition?.replace(defaultVar, `"${formState[defaultValue]}"`);
                              continue;
                            }

                            if (getObjValueInDeep(resItem, defaultValue).length > 0 || resItem[defaultValue]) {
                              condition = condition?.replace(defaultVar, `"${getObjValueInDeep(resItem, defaultValue) ?? resItem[defaultValue]}"`);

                            }


                          }
                        }


                        if (!isDefaultVarExpression(condition)) {
                          newConditions.push(condition);
                        }
                      }

                      return newConditions.some(condition => {
                        return eval(condition) ?? false;
                      });
                    });
                  }

                  if (header?.format_rules?.default_value) {
                    let value = null;
                    const defaultValue1 = getVariableDefaultValue(header.format_rules.default_value);
                    const item = sbxItem ?? responseOpt.items[0];

                    if (item) {
                      //Check if nobody condition was true

                      if (!sbxItem && newConditions.length > 0) {
                        value = header.default
                      } else {
                        if (item.hasOwnProperty(defaultValue1)) {
                          value = item[defaultValue1];
                        } else {
                          value = item['_KEY'];
                        }
                      }

                    }

                    if (value || value === 0) {
                      setValue(getHeaderInputName(), value);
                      if (header.extra_values && header.extra_values.length > 0) {
                        for (const extra_value of header.extra_values) {
                          setValue(extra_value, value);
                        }
                      }
                    }
                  } else {
                    if (responseOpt.items[0] && responseOpt.items[0]['_KEY'] && getValue(getHeaderInputName())) {
                      setValue(getHeaderInputName(), responseOpt.items[0]['_KEY']);
                    }
                  }

                  if (responseOpt?.items?.length > 1 && header.type === "list_provider"){
                    dispatchReferenceFields({name: (responseOpt as ProviderOptionsResponse).row_model ?? "", value: responseOpt.items})
                  }

                  setLoading(State.RESOLVED);
                } else {

                  setLoading(State.REJECTED);
                }
              } else {
                setLoading(State.REJECTED);
              }
            } else {

              if (header.default === 0 || (header.default && !isDefaultVarExpression(header.default as string))) {
                setValue(getHeaderInputName(), header.default);
              }

              setLoading(State.RESOLVED);
            }
          } else {
            setLoading(State.REJECTED);
          }
        } else {
          if (tableState.form[dependency] && tableState.model) {

            const reference = (tableState.model.find(model => model.name === dependency) as TableFormColumn)?.reference
            if (reference) {
              const item = tableState.reference_fields[reference].find((nItem: { [key: string]: string }) => nItem._KEY === tableState.form[dependency])
              if (item && header.format_rules?.default_value) {

                const defaultValue = getVariableDefaultValue(header.format_rules?.default_value)

                setValue(getHeaderInputName(), getObjValueInDeep(item, defaultValue));
              }
            }
          }
        }
      }
    };

    if (header.format_rules?.dependencies) {
      getDependencyOption();
    }
  }, [tableState.form]);

  let input = null;

  const getOptionsByModel = async (value: string) => {

    if (value) {
      const row_model = header.reference ?? getHeaderInputName()
      let query = new Find(row_model, 0);
      if (header.search_by) {

        header.search_by.forEach(search => {
          query.orWhereItContains(search, value);
        });
      }



      let references: string[] = []

      if (header && header.sub_columns && header.sub_columns.length > 0) {
        header?.sub_columns.forEach((sub_column: ColumnsLabels) => {
          if (sub_column.compound_name) {
            const varList = getDefaultVarsFromStr(sub_column.compound_name);
            if (varList && varList.length > 0) {
              varList.forEach(strVar => {
                const nameVar = getVariableDefaultValue(strVar);
                if (header.sub_columns) {
                  const sub_references = nameVar.split('.')


                  if (sub_references.length > 1) {
                    sub_references.splice(-1, 1)

                    sub_references.forEach(sub_reference => {
                      references.push(sub_reference)
                    })
                  }
                }
              });
            }

          } else {
            references.push(sub_column.name?.split('.')[0])
          }
        })

      }

      if (references.length > 0){
        query.fetchModels(references)
      }



      const query_build: SbxQuery = query.compile();
      setLoading(State.PENDING);
      const res: SbxResponse = await debounceTime(getSbxModelFields, {
        provider: {
          query: JSON.stringify({
            row_model: query_build.row_model,
            where: query_build.where,
            fetch: query_build.fetch
          })
        }
      }, 500);
      if (res?.success && res?.results) {

        let options = getReferenceSelectOptions({
          header,
          options: res.results
        });
        options = removeDuplicateFromArrayObj(options, 'value');
        setSelectOptions(header.format_rules?.condition_order_by ? options : options.sort((a: { label: string, value: string }, b: { label: string, value: string }) => a.label.localeCompare(b?.label ?? '')));
        dispatchReferenceFields({name: row_model, value: res.results})
        setLoading(State.RESOLVED)
      } else {
        setLoading(State.REJECTED);
      }
    }


  };

  const showLoadingLabel = () => {
    return loading === State.PENDING && header.sub_type === "CURRENCY"
  }

  const getDisabledReferenceSelect = () => {
    if (header.table_column_type === SelectSubType.SEARCHEABLE) {
      return header.read_only
    } else {
      return (tableState.loading === State.PENDING || header.read_only || loading === State.PENDING || selectOptions.length === 0)
    }
  }

  switch (inputType) {
    case SbxModelField.REFERENCE:
      input = <SelectComponent id={'select_input_table_' + header.name} name="select_input"
                               options={(selectOptions ?? [])}
                               value={selectValue}
                               sortOptions={false}
                               onInputChange={getOptionsByModel}
                               noOptionsMessage={header.placeholder}
                               loading={(tableState.loading === State.PENDING || loading === State.PENDING || tableState.selectLoading.includes(TableLoadingState.PENDING_MODELS) || tableState.selectLoading.includes(TableLoadingState.PENDING_PROVIDER))}
                               disabled={getDisabledReferenceSelect()}
                               placeholder={header.placeholder ?? t('select_placeholder')}
                               onChange={evt => {
                                 handleSelect(evt);
                                 // dispatchReferenceField({name: header.name, value: evt?.value});
                               }}/>;
      break;

    case 'list_provider': {
      switch (header.render_type) {
        case 'radio_button':
          input = <div className="btn-group d-flex align-items-center gap-3" role="group">
            {selectOptions.map(option => (
              <div className="d-flex align-items-center mt-2 gap-2" key={option.value}>
                <input type="radio" disabled={header.read_only} id={option.value} className="mt-1" onChange={(evt) => {
                  handleSelect(option);
                }} name={option.value} value={option.value} checked={selectValue?.value === option.value}/>
                <label htmlFor={option.value}>{option.label}</label>
              </div>
            ))}
          </div>;
          break;

        case 'field':
          switch (header.sub_type) {
            case SubType.CURRENCY:
              input = <div className="input-group mb-3">
                <Controller
                  control={control}
                  defaultValue={defaultValue}
                  name={getHeaderInputName()}
                  rules={{
                    pattern: /^[0-9]{6,15}/i,
                    required: header.required
                  }}
                  render={({field: {onChange, name, value}}) => (
                    <CurrencyComponent
                      id={header.name}
                      value={value}
                      disabled={(tableState.loading === State.PENDING || header.read_only)}
                      defaultValue={defaultValue}
                      onChange={onChange}
                      name={name}/>
                  )}/>

              </div>;
              break;
            default:
              input = <div className="input-group mb-3">
                <input className="form-control"
                       id={header.name}
                       defaultValue={defaultValue}
                       type={header.sub_type}
                       disabled={(tableState.loading === State.PENDING || header.read_only)}
                       {...register(getHeaderInputName(), {required: header.required})}/>
                {loading === State.PENDING && <span className="input-group-text bg-white">
              <FontAwesomeIcon icon={faSpinner} pulse/>
            </span>}
              </div>;
              break;
          }


          break;
        default:
          input = <SelectComponent id={'select_input_table_' + header.name} name="select_input"
                                   options={header.format_rules?.condition_order_by ? selectOptions : selectOptions.sort((a, b) => a.label.localeCompare(b.label))}
                                   value={selectValue}
                                   sortOptions={false}
                                   noOptionsMessage={header.placeholder}
                                   onInputChange={evt => {
                                     if (header.table_column_type === SelectSubType.SEARCHEABLE) {
                                       getSearchableData(header, evt)
                                     }
                                   }}
                                   loading={(tableState.loading === State.PENDING || loading === State.PENDING || tableState.selectLoading.includes(TableLoadingState.PENDING_PROVIDER) || tableState.selectLoading.includes(TableLoadingState.PENDING_MODELS))}
                                   disabled={(tableState.loading === State.PENDING || header.read_only || loading === State.PENDING)}
                                   placeholder={header.placeholder ?? t('select_placeholder')}
                                   onChange={(evt) => {
                                     handleSelect(evt);
                                     // dispatchForm({name: getHeaderInputName(), value: evt?.value});
                                     // setSelectValue(evt);
                                   }}/>;
          break;
      }
      break;
    }
    case SbxModelField.INT:
      input = <input className="form-control"
                     id={header.name}
                     defaultValue={defaultValue}
                     type="number"
                     disabled={(tableState.loading === State.PENDING || header.read_only)}
                     {...register(getHeaderInputName(), {required: header.required})}/>;
      break;
    case SbxModelField.BOOLEAN:
      return <Col xs="1" key={header.name + ' ' + index} className="order-1">
        <FormGroup>
          <label
            className="form-control-label"
            htmlFor={header.name}
          >
            {header.label}
          </label>

          <Switch disabled={(tableState.loading === State.PENDING || header.read_only)}
                  defaultChecked={item ? item[getHeaderInputName()] : false}
                  checkedChildren={t('common:yes')} unCheckedChildren="No"
                  onChange={checked => dispatchForm({
                    name: getHeaderInputName(),
                    value: checked
                  })}/>
        </FormGroup>
      </Col>;
    case SbxModelField.TEXT:
      input = <textarea className="form-control"
                        id={header.name}
                        defaultValue={defaultValue}
                        disabled={(tableState.loading === State.PENDING || header.read_only)}
                        {...register(getHeaderInputName(), {required: header.required})}
      />;
      break;
    case 'time':
      input = <input className="form-control"
                     id={header.name}
                     defaultValue={defaultValue}
                     type="time"
                     disabled={(tableState.loading === State.PENDING || header.read_only)}
                     {...register(getHeaderInputName(), {required: header.required})}/>;
      break;
    case SbxModelField.DATE:
      input = <Controller
        control={control}
        name={getHeaderInputName()}
        defaultValue={getDateValue()}
        rules={{required: header.required}}
        render={({field: {onChange}}) => {
          return (
            <DateComponent id="date_input"
                           value={getDateValue(header?.value_type)}
                           placeholder={'Seleccionar fecha'}
                           isOutsideRange={() => false}
                           disabled={(tableState.loading === State.PENDING || header.read_only)}
                           onChange={(val: any) => {
                             onChange(setValueType(new Date(val), header.value_type));
                           }}/>
          );
        }}
      />;
      break;
    default:
      switch (header.sub_type) {
        case SubType.CURRENCY:
          input = <Controller
            control={control}
            defaultValue={defaultValue}
            name={getHeaderInputName()}
            rules={{
              pattern: /^[0-9]{6,15}/i,
              required: header.required
            }}
            render={({field: {onChange, name, value}}) => (
              <CurrencyComponent
                id={header.name}
                value={header.read_only ? defaultValue ?? value : value}
                disabled={(tableState.loading === State.PENDING || header.read_only)}
                defaultValue={defaultValue}
                onChange={onChange}
                name={name}/>
            )}/>
          break;
        default:
          input = <input className="form-control"
                         id={header.name}
                         defaultValue={defaultValue}
                         type="text"
                         disabled={(tableState.loading === State.PENDING || header.read_only)}
                         {...register(getHeaderInputName(), {required: header.required})}/>;
          break;
      }

      break;
  }


  return <div className={`${isGroupInput ? 'col' : 'col-12 col-md-6 col-lg-4'} ${header.hide ? 'd-none' : ''}`}
              key={header.name + '_' + (index >= 0 ? index : uuidV4())}>
    <FormGroup>
      <div className="d-flex align-items-center">
        <label
          className="form-control-label"
          htmlFor={header.name}
        >
          {header.label}

          {showLoadingLabel() && <FontAwesomeIcon icon={faSpinner} pulse/>}
        </label>
      </div>
      {input}
    </FormGroup>

  </div>;
};

export default TableInputComponent;

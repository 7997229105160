export interface ReportMetadata {
  x_axis: string;
  y_axis: string;
  split_by: string ;
  total: boolean;
}

export interface Alias {
  key: string;
  ttl: number;
  update: boolean;
}

export interface FilterReport {
  table_name: string,
  label: string,
  type?: "date_range",
  hide?: true,
  start_date?: "${date_start_month}" | string,
  filter_operator?: string,
  end_date?: "${date_end_month}" | string,
  field?: string,
  date_type?: "numberDate"
  dependency?: string
}

export type ReportTypeChart = 'table' | 'null' | 'barchart' | 'linechart' | 'piechart' | 'horizontal_barchart';

export interface Report {
  _KEY?: string
  name: string;
  filter: string;
  query: string;
  custom: boolean
  active: boolean;
  sort: string;
  parent_group: string;
  group: boolean;
  show_all_columns: boolean;
  dashboard_view: boolean;
  order_group: number;
  ncolumn: number;
  columns_to_summarize: string;
  metadata: string | ReportMetadata;
  visible_for: string;
  remove_empty_columns?: boolean;
  handleQuery?: boolean;
  isDrillDownQuery?: boolean // Check if it drill down to avoid override query
  drillDownLevel?: number // Check drill down level
  custom_column: string;
  type: ReportTypeChart | null
  report_in_process?: boolean
  sort_list?: number;
  truncate?: string | TruncateReport;
  alias? : string |  Alias;
  created_by?: number
  _META?: any
}

export interface TruncateUpdateFields {
  model: {name: string, label: string}[]
  field: {name: string, label: string}[]
}

export interface CacheTruncate {
  domain: string;
  name: string;
  from: string //yyyymmdd  string;
  to: string // yyyymmdd string  ;
  append_type: "columns" | "rows" ;
  append_index: string //nombre de la columna indice string;
  append_at: "start" | "end"
  append_format: string //formato de fecha string;
  force_save: boolean
  force_reload: boolean
  temporal_id?: string
  cached_by_range?: "monthly"
  force_save_from?: string
  force_save_to?: string

}

export interface BaseTruncateReport {
  type: "date" | "text";
  model: string,
  field?: string,
  range?: number | string,
  format?: string
  value?: string,
  label_field?: string,
  temporal_id?: string
  dynamic_from?: string
  dynamic_range?: string
  dynamic_start?: boolean // if true, start_date will be dynamic with start of month/week/year
  cache?: CacheTruncate
}

export interface TruncateReport extends BaseTruncateReport{
  update_fields?: string | TruncateUpdateFields
  others?: BaseTruncateReport[]

}


// export type SourceFrom = "sbx-event" | "sbx-data" | "sbx-workflow" | "sbx-crm-user"
export enum SourceFrom {
  SBX_EVENT = 'sbx-event',
  SBX_DATA = 'sbx-data',
  SBX_WORKFLOW = 'sbx-workflow',
  SBX_CRM_USER = 'sbx-crm-user'
}

export type merge_type = "inner" | "left" | "outer" | "right"

export interface Source {
  action?: "merge" | "get";
  from: SourceFrom;
  in?: number;
  merge_type: merge_type;
  with: string;
  override_auth?: string;
  index_column?: string;
  main_column?: string;
  pagination?: boolean;
  sources?: Source[];
  field?: string
  filters?: SourceFilter[];
  fetch?: string[];
  temporal_id?: string;
}

export type FilterOperator = "=" | "<" | ">" | 'is null' | 'is not null' | '>=' | "<=" | string
export const filterMathOperator = ["=", "<", ">", '+', '*', '>=', "<=", "-", "/"]

export interface SourceFilter {
  logic_operator: "and" | "or" | string;
  filter_operator: FilterOperator;
  field: string;
  value: any
  model?: string
}

// export enum ActionFilterOperator  = FilterOperator | '!=' | '=='
export enum ActionFilterOperator {
  EQUAL_TO = '==',
  DIFFERENT_OF = '!=',
  GREATER_THAN = '>',
  GREATER_OR_EQUAL_THAN = '>=',
  SMALLER_OR_EQUAL_THAN = '<=',
  SMALLER_THAN = '<',
  EXIST = 'is not null',
  NOT_EXIST = 'is null',
}


export type ActionType =
  "group_by"
  | "rename"
  | 'select'
  | 'limit'
  | 'sort'
  | 'transform'
  | 'filter'
  | 'default_values'
  | 'merge'
  | 'ml'
  | 'pivot' | 'save' | 'drill_down'

export interface AnalyticBusinessDay {
  source: Source;
  year_column: string;
  month_column: string;
  business_day_column: string;
  agg: string;
  multiply_by: string;
  temporal_id?: string;
}

export interface AggAnalytic {
  [key: string]: (string | AnalyticBusinessDay)[]
}

export interface AnalyticQueryAction {
  type: ActionType;
  agg?: AggAnalytic;
  hierarchy?: string[]
  agg_herarchy?: AggAnalytic[]
  index_columns?: string[]
  columns?: string[] | { [key: string]: any };
  renamed_columns?: { [key: string]: string };
  ascending?: boolean;
  top?: number;
  temporal_id?: string;
  dependency_action_id?: string;
  filter?: string;
  transformation?: string | number;
  name?: string;
  index?: number;
  subIndex?: number;
  source?: Source;
  actions?: AnalyticQueryAction[][];
  main_column?: string;
  drill_down_execution_index?: number
  index_column?: string;
  merge_type?: merge_type
  subtype?: 'forecast' | 'monetary_segmentation' | 'binary_classifier' | 'segmentation' | 'data_transform'| 'date_formatter'| 'date_operations' | 'drill_down';
  x?: string;
  y?: string;
  seasonal?: number;
  forecast_from?: string; //string desde donde
  forecast_to?: string;  // string hasta donde
  target?: string
  customer_id?: string,
  invoice_id?: string,
  invoice_date?: string,
  invoice_total?: string
  id?: string,
  in?: number
  domain?: number
  with?: string
  truncate?: TruncateReport
  n_segments?: number
}

export interface AnalyticQuery {
  actions: AnalyticQueryAction[][];
  preview?: true
  source: Source;
  truncate?: TruncateReport | string;
  alias?: Alias;
}

export interface BaseAnalyticQuery {
  actions: AnalyticQueryAction[];
  preview?: boolean
  source: Source;
  truncate?: TruncateReport;
  alias?: Alias;
}



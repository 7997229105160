import React, {useEffect, useState} from "react";
import FullCalendar, {EventClickArg, EventDropArg} from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listGridPlugin from "@fullcalendar/list";
import interactionPlugin, {DateClickArg, EventResizeDoneArg} from "@fullcalendar/interaction";
import ModalComponent from "../../Modal/ModalComponent/ModalComponent";
import {convertDateToNumberDate, convertNumberAndTimeToDate, getFormatDate, getTimeFormat} from "../../../../utils";
import {Appointment} from "../../../../types/Appointment";


interface IProps {
  disabled?: boolean;
  hiddenDays?: number[];
  readOnly?: boolean;
  loading?: boolean;
  placeholder?: string;
  eventDrop?: (e: EventDropArg) => void;
  dateClickEvent?: (e: DateClickArg) => void;
  eventResize?: (e: EventResizeDoneArg) => void;
  value?: Date | null;
  timeSecondary?: Date;
  currentAppointment?: Appointment;
  events?: Appointment[];
  eventUpdate?: (date: Date | null, appointment: Appointment[], event: MouseEvent) => void;
}

const CalendarPickerComponent = (props: IProps) => {
  const {
    loading,
    placeholder,
    value,
    timeSecondary,
    eventDrop,
    eventResize,
    events = [],
    dateClickEvent,
    hiddenDays,
    disabled,
    currentAppointment,
    eventUpdate = (date: Date | null, appointment: Appointment[], event: MouseEvent) => null
  } = props;

  const [open, toggle] = useState(false);
  const [val, setVal] = useState("");

  const onToggle = () => toggle(e => !e);

  function updateEvent(e: EventClickArg) {
    if (value && timeSecondary) {
      const app = {
        ...e.event._def.extendedProps.data,
        start_date: convertDateToNumberDate(value),
        due_date: convertDateToNumberDate(timeSecondary),
        start_time: getTimeFormat(value).stringTime,
        end_time: getTimeFormat(timeSecondary).stringTime
      }
      eventUpdate(e.event.start, [app], e.jsEvent);
    }

  }

  useEffect(() => {
    const isEqualDate = value && timeSecondary && convertDateToNumberDate(value) === convertDateToNumberDate(timeSecondary);
    const newVal = value && timeSecondary ?
      `${getFormatDate(value, "MMMM d, yyyy h:mm aa")} to ${getFormatDate(timeSecondary, isEqualDate ? "h:mm aa" : "MMMM d, yyyy h:mm aa")}` : "";
    if (newVal !== val) {
      setVal(newVal);
    }
  }, [value, timeSecondary])

  const getColor = (appo: Appointment) => {
    return currentAppointment?._KEY === appo._KEY ? "orange" : undefined;
  };

  const newEvent = (!currentAppointment && value && timeSecondary) ? [
    {
      color: "red",
      allDay: false,
      start: value,
      end: timeSecondary,
      title: 'no defined',
      data: {} as Appointment,
      editable: true,
      selectable: true
    }
  ] : [];

  const eventsMap = events.map(appo => {
    let start = convertNumberAndTimeToDate(appo.start_date, appo.start_time);
    let end = appo.due_date ? convertNumberAndTimeToDate(appo.due_date, appo.end_time ?? "00:00") : undefined;
    if (appo._KEY === currentAppointment?._KEY && value) {
      if (value) start = value;

      if (timeSecondary) end = timeSecondary;
    }
    return {
      color: getColor(appo),
      allDay: appo.all_day,
      start,
      end,
      title: appo.summary,
      data: appo,
      editable: true,
      selectable: true
    }
  });

  return (
    <div>
      <input
        disabled={disabled}
        value={val}
        readOnly
        className="form-control"
        type="text"
        placeholder={placeholder}
        onClick={onToggle}/>

      <ModalComponent
        size={"xl"}
        noFooter
        isOpen={open}
        toggle={onToggle}>
        <div className="">
          <FullCalendar
            hiddenDays={hiddenDays}
            events={[...eventsMap, ...newEvent]}
            headerToolbar={{
              left: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
              center: "title"
            }}
            initialView="dayGridMonth"
            plugins={[dayGridPlugin, timeGridPlugin, listGridPlugin, interactionPlugin]}
            editable={!loading}
            selectable={!loading}
            monthMode
            droppable={!loading}
            eventDrop={eventDrop}
            dateClick={dateClickEvent}
            eventClick={updateEvent}
            eventResize={eventResize}
          />
        </div>
      </ModalComponent>
    </div>
  )
}

export default CalendarPickerComponent;
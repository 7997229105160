import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {actionsAuth} from "../store/Auth/Slice";
import {useRouter} from "next/router";
import {AuthStates} from "../store/Auth/Types";
import {authReducer} from "../store/Selectors";
import {START_PAGE_ON, START_PAGE_PASSWORD_EXPIRATION} from "../pages";


const allowedRoutes = ["forgot-password", "change-password", "email-verification", "update-password"];


const ignoreMe = ["task-view"];

const AuthValidation = ({children}: { children: JSX.Element | JSX.Element[] }) => {
  const {state, user, path} = useSelector(authReducer);

  const dispatch = useDispatch();
  const history = useRouter();

  useEffect(() => {
    const ignore = !ignoreMe.some(e => window.location.pathname.includes(e));
    if (localStorage.getItem("crm_token") && ignore) {
      dispatch(actionsAuth.me());
    } else if (ignore) {
      const pathname = window.location.pathname;
      if (!allowedRoutes.some(route => history.pathname.includes(route)) && !window.location.href.includes("?")) {
        history.push(`/auth/login${pathname && !pathname.includes("auth") ? "?" + pathname : ""}`);
      }
    }
  }, [dispatch])

  useEffect(() => {
    switch (state) {
      case AuthStates.MFA_VERIFICATION:
        history.push(`/auth/mfa/email-verification`);
        break;

      case AuthStates.USER_PASSWORD_EXPIRED:
        history.push(START_PAGE_PASSWORD_EXPIRATION);
        break;

      case AuthStates.AUTHENTICATED:
        if (allowedRoutes.concat("/login").some(path => history.pathname.includes(path))) {
          if (path) {
            history.push(path);
            dispatch(actionsAuth.changePath(undefined));
          } else {
            history.push(user.config?.sbx_crm.startPage ? user.config?.sbx_crm.startPage : START_PAGE_ON);
          }
        }
        break;
    }
  }, [state]);


  return (
    <>
      {children}
    </>
  )
}

export default AuthValidation;

import Axios, {AxiosResponse} from 'axios';
import {Response} from '../types/Response';
import * as Sentry from '@sentry/nextjs';

// const sbxCoreService = new SbxCoreService();
// const sbxSessionService = new SbxSessionService(sbxCoreService);
//
//
// typeof window !== "undefined" && sbxSessionService.initialize(parseInt(process.env.NEXT_PUBLIC_SBX_DOMAIN), process.env.NEXT_PUBLIC_SBX_APP_KEY);
//
// const runCS = function (key: string, params: any) {
//   return sbxCoreService.run(key, params, process.env.NEXT_PUBLIC_TEST_MODE === "true");
// };
//
// initialize(sbxCoreService, sbxSessionService, '');


const http = Axios.create({

  headers: {
    'authorization': `Bearer ${typeof localStorage !== "undefined" ? localStorage.getItem("crm_token") : ""}`,

  }
});


export function setHeaderToken(token: string) {
  localStorage.setItem('crm_token', token);
  http.defaults.headers = {Authorization: "Bearer " + token};
}

http.interceptors.response.use(function (response: AxiosResponse) {
  return response;
}, error => {
  console.error(error);
});


export function get(url: string, params?: any): Promise<Response> {
  return http.get(url, {params}).then(res => res.data)
    .catch((err) => {
      Sentry.captureException(err);
      return Promise.resolve({success: false})

    });
}

export function post(url: string, params?: any, queryParams?: any, timeout?: number): Promise<Response> {
  return http.post(url, params, {params: queryParams, timeout, timeoutErrorMessage: "request timeout!"}).then(res => {
    return res?.data ? res.data : res;
  }).catch(reason => {
    Sentry.captureException(reason);
    return Promise.reject(reason);
  });
}

export function put(url: string, params?: any): Promise<Response> {
  return http.put(url, params).then(res => {
    return (res.data ? res.data : res) ?? {};
  }).catch(reason => {
    Sentry.captureException(reason);
    return Promise.reject(reason);
  });
}

export function deleteReq(url: string, params?: any): Promise<Response> {
  return http.delete(url, params).then(res => res.data).catch(reason => {
    Sentry.captureException(reason);
    console.error(reason.message);
  });
}

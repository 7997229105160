import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {State} from "../types/State";

interface IState {
  state: State;
}

const initialState: IState = {
  state: State.IDLE,
}

const slice = createSlice({
  name: "globalStateReducer",
  initialState: initialState,
  reducers: {
    changeActive(state, action: PayloadAction<State>) {
      state.state = action.payload;
    }
  }
});

export const stateActions = slice.actions;
export default slice.reducer;
